import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography, ListItemIcon, Chip, Box, Avatar } from '@mui/material';
import { GraphicEq, Build, Memory } from '@mui/icons-material'; // Icons for GPUs
import { SiIntel, SiNvidia } from 'react-icons/si';
import { BsAmd } from 'react-icons/bs';

const GpuLibSelect = () => {
  const [selectedGpu, setSelectedGpu] = useState('');
  const [selectedLib, setSelectedLib] = useState('');

  // Example GPU options with libraries and descriptions
  const gpuOptions = [
    {
      value: 'nvidia',
      label: 'NVIDIA GPU',
      description: 'High-performance graphics and computation with CUDA and cuDNN support.',
      icon: <SiNvidia />,
      libraries: [
        {
          value: 'cuda',
          label: 'CUDA',
          description: 'A parallel computing platform and programming model.',
          image: 'https://d29g4g2dyqv443.cloudfront.net/sites/default/files/akamai/cuda/images/CUDALibs/cublas-flat.svg', // Replace with actual image URL
        },
        {
          value: 'cudnn',
          label: 'cuDNN',
          description: 'A GPU-accelerated library for deep neural networks.',
          image: 'https://d29g4g2dyqv443.cloudfront.net/sites/default/files/akamai/cuda/images/CUDALibs/cuFFT-flat.svg', // Replace with actual image URL
        },
      ],
    },
    {
      value: 'amd',
      label: 'AMD GPU',
      description: 'Efficient GPUs for gaming and compute with OpenCL support.',
      icon: <BsAmd />,
      libraries: [
        {
          value: 'rocm',
          label: 'ROCm',
          description: 'Open-source platform for AMD GPUs for machine learning.',
          image: 'https://example.com/rocm_image.png', // Replace with actual image URL
        },
        {
          value: 'opencl',
          label: 'OpenCL',
          description: 'A framework for writing programs that execute on heterogeneous platforms.',
          image: 'https://example.com/opencl_image.png', // Replace with actual image URL
        },
      ],
    },
    {
      value: 'intel',
      label: 'Intel GPU',
      description: 'Intel GPUs with Intel oneAPI support for acceleration.',
      icon: <SiIntel />,
      libraries: [
        {
          value: 'oneapi',
          label: 'oneAPI',
          description: 'A unified programming model for Intel GPUs.',
          image: 'https://example.com/oneapi_image.png', // Replace with actual image URL
        },
        {
          value: 'opencl',
          label: 'OpenCL',
          description: 'Open-source framework for writing programs for Intel GPUs.',
          image: 'https://example.com/opencl_intel_image.png', // Replace with actual image URL
        },
      ],
    },
  ];

  const handleGpuChange = (event) => {
    setSelectedGpu(event.target.value);
    setSelectedLib(''); // Reset library when GPU is changed
  };

  const handleLibChange = (event) => {
    setSelectedLib(event.target.value);
  };

  const selectedGpuObj = gpuOptions.find((gpu) => gpu.value === selectedGpu);
  const selectedLibObj = selectedGpuObj
    ? selectedGpuObj.libraries.find((lib) => lib.value === selectedLib)
    : null;

  return (
    <Box>
      {/* Chip for selecting GPU */}
      <FormControl fullWidth>
        <Typography variant="body2" sx={{mb:1}}>Select GPU</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {gpuOptions.map((gpu) => (
            <Chip
              key={gpu.value}
              label={gpu.label}
              icon={gpu.icon}
              onClick={() => handleGpuChange({ target: { value: gpu.value } })}
              color={selectedGpu === gpu.value ? 'primary' : 'default'}
              clickable
            />
          ))}
        </Box>
      </FormControl>

      {/* Select for GPU Libraries */}
      {selectedGpu && (
        <FormControl fullWidth sx={{ mt: 2 }}>
       
          <Select
            labelId="lib-select-label"
            value={selectedLib}
            onChange={handleLibChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <Typography color="textSecondary">Select a Library</Typography>;
              }
              const selectedLibrary = selectedGpuObj.libraries.find((lib) => lib.value === selected);
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar variant='square' src={selectedLibrary.image} sx={{ width: 24, height: 24, mr: 1 }} />
                  {selectedLibrary.label}
                </Box>
              );
            }}
          >
            {selectedGpuObj &&
              selectedGpuObj.libraries.map((lib) => (
                <MenuItem key={lib.value} value={lib.value}>
                  <ListItemIcon>
                    <Avatar variant='square' src={lib.image} sx={{ width: 32, height: 32 }} />
                  </ListItemIcon>
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="body1" fontWeight="bold">
                      {lib.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {lib.description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      {/* Display selected library details */}
      {selectedLibObj && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Library Details</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar variant='square' src={selectedLibObj.image} sx={{ width: 48, height: 48, mr: 2 }} />
            <Box>
              <Typography variant="h6">{selectedLibObj.label}</Typography>
              <Typography variant="body1">{selectedLibObj.description}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GpuLibSelect;
