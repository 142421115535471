import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Alert, Paper, Typography, Box, Chip, Badge } from '@mui/material';
import axios from 'axios';
import { FaServer } from 'react-icons/fa';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../configApp';

// MetricsCard Component
export function MetricsCard({ data }) {
    return (
        <Paper variant="outlined" sx={{ borderRadius: "12px", p: 2, pb: 0.5 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>{data.title}</Typography>
            <Typography variant="body2" sx={{ fontSize: "12px", color: "grey" }}>{data.subtitle}</Typography>

            <Grid
                container
                direction="row"
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" sx={{ my: 1, fontWeight: "bold" }}>{data.value}</Typography>
                <Typography variant="body2" sx={{ my: 1, fontWeight: "bold" }}>{data?.extra}</Typography>
            </Grid>
        </Paper>
    );
}

// Main MetricsDisplay Component
const MetricsDisplay = ({ ipAddress }) => {
    const [metricsData, setMetricsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch metrics data on component mount
    useEffect(() => {
        setLoading(true);
        setError(null); // Reset error state when fetching new data
        axios
            .post(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/get-monolith-metrics`, { ipAddress: ipAddress })
            .then((response) => {
                console.log(response.data);
                setMetricsData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching metrics:', error);
                setLoading(false);
                setError('Monolith is down or not accessible'); // Set error message
            });
    }, []); // Empty array ensures this runs once when the component mounts

    return (
        <div style={{ marginTop: "10px" }}>
            {loading ? (

                <Grid
                    container
                    direction="row"
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >

                    <CircularProgress size={20} />
                    <Typography sx={{ ml: 2, textTransform: "capitalize" }}>{"Fechting metrics.."}</Typography>
                </Grid>
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                <Box>
                    {metricsData.map((metric) => (
                        <Grid container spacing={2} key={metric.ID}>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 2 }}>

                                    <Grid
                                        container
                                        direction="row"
                                        sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Badge badgeContent={1} color='info' variant='dot' >
                                            <FaServer style={{ fontSize: "20px" }} />
                                        </Badge>

                                        <Chip sx={{ ml: 1 }} variant='filled' label={metric.name} />

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{
                                    title: "CPU Usage",
                                    subtitle: 'CPU Usage Percentage',
                                    value: `${metric.CPUPerc}`,
                                    noLinearProgress: true,
                                    status: metric.CPUPerc / 100,
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{
                                    title: "Memory Usage",
                                    subtitle: 'Memory Usage Percentage',
                                    value: `${metric.MemPerc}`,
                                    extra: `(${metric.MemUsage})`,
                                    noLinearProgress: true,
                                    status: metric.MemPerc / 100,
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{
                                    title: "Network I/O",
                                    subtitle: 'Network I/O (Received/Sent)',
                                    value: `${metric.NetIO}`,
                                    noLinearProgress: false,
                                    status: 0, // No performance status for Network I/O
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{
                                    title: "Block I/O",
                                    subtitle: 'Block I/O (Read/Written)',
                                    value: `${metric.BlockIO}`,
                                    noLinearProgress: false,
                                    status: 0, // No performance status for Block I/O
                                }} />
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
        </div>
    );
};

export default MetricsDisplay;
