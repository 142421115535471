import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
    Grid,
    LinearProgress,
    Fade,
    List,
    Tooltip,
    Badge,
    ListItemButton,
    Chip
} from "@mui/material";
import { AiOutlineArrowLeft, AiOutlineGithub } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { FiArrowUpLeft } from "react-icons/fi";
import { CheckCircle, Close } from "@mui/icons-material";
import moment from "moment";
import { Octokit } from "@octokit/rest";
import { CHECK_GITHUB_REPO_APP, FETCH_USER_PAT, START_BUILD } from "../Home/CreateNewApp/StepTwo";
import { useMutation, useQuery } from "@apollo/client";
import { decodeAes } from "../Deployments/Services/GithubTimeline";

function parseGitHubUrl(url) {
    const regex = /github\.com\/([^\/]+)\/([^\/]+)/;
    const match = url.match(regex);
    return match ? [match[1], match[2]] : [null, null];
}

function BranchesList({ githubUrl, setgithubBranch, githubBranch }) {
    const [branches, setBranches] = useState([]);
    const octokit = new Octokit(); // You can pass an auth token if needed

    const [owner, repo] = parseGitHubUrl(githubUrl);

    useEffect(() => {
        const fetchBranches = async () => {
            if (owner && repo) {
                try {
                    const response = await octokit.rest.repos.listBranches({
                        owner,
                        repo,
                    });
                    setBranches(response.data);
                } catch (error) {
                    console.error('Error fetching branches:', error);
                }
            }
        };

        fetchBranches();
    }, [owner, repo]);

    return (
        <div>
            <Grid container spacing={1}>
                {branches.length > 0 && (
                    <Grid item>
                        <Typography>Select a branch: </Typography>
                    </Grid>
                )}
                {branches.map(branch => (
                    <Grid item key={branch.name}>
                        <Chip
                            variant="filled"
                            color={githubBranch === branch.name ? "primary" : "default"}
                            label={branch.name}
                            onClick={() => setgithubBranch(branch.name)}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

function MonolithGithubDeploymentOption({
    directBuild,
    isQuickApps,
    revertApploading,
    DPLoad,
    serviceName,
    handleDirectBuildFileDeploy,
    setbuildFileUrl,
    deploymentType,
    setdeploymentType,
}) {

    const [githubLink, setgithubLink] = useState("");
    const [listofrepo, setlistofrepo] = useState([]);
    const [showGithubFetchLoading, setshowGithubFetchLoading] = useState(false);
    const [githubBranch, setgithubBranch] = useState("main");
    const [startBuildImageName, setstartBuildImageName] = useState("");
    const [gitUrlValidation, setGitUrlValidation] = React.useState(false)

        const [startBuild, { data: buildData, loading: buildLoad, error: buildErr }] = useMutation(START_BUILD, {
            onError: (e) => {
             
              
            },
            onCompleted: () => {
             
            }
        });

    const handleBuildStartBuild = () =>{
        var imageName =  `nife123/${serviceName}:deployment-${Math.random().toString(20).substr(2, 6)}`
        setstartBuildImageName(imageName)
        
        var temp;
        if (githubLink.endsWith(".git")) {
            temp = `${githubLink.substring(0, githubLink.length - 4)}/archive/refs/heads/${githubBranch}.zip`
        }
        else {
            temp = `${githubLink}/archive/refs/heads/${githubBranch}.zip`
        }

        startBuild({
            variables: {
                appId: serviceName,
                sourceUrl: temp,
                sourceType: "static react",
                buildType: "Builtin",
                fileExtension: "zip",
                dockerFile: null,
                dockerFilePath: "",
                imageTag: imageName
            }
        })
    }
        const handleGithubInputUrl = useCallback((event) => {
            setshowGithubCheckError(false)
            setgithubLink(event.target.value)
            valuevalidateGitUrl(event);
            if (!selectedfromgithublist) {
                checkGihubRepo({ variables: { githubUrl: event.target.value } })
            }
    
    
        }, []);


    const valuevalidateGitUrl = (e) => {

        const checkvalue = e.target.value.substring(0, 19) === "https://github.com/"

        if (checkvalue.length > 0) {
            setGitUrlValidation(false)
        }
        setGitUrlValidation((checkvalue) ? false : true)

        if (e.target.value === "") {
            setGitUrlValidation(false)
        }
    }

    const [showGithubCheckError, setshowGithubCheckError] = useState(false);
    const [selectedfromgithublist, setselectedfromgithublist] = useState(false);

    const [checkGihubRepo, { data: gitrepo, loading: gitrepoLoad, error: gitrepoErr }] = useMutation(CHECK_GITHUB_REPO_APP, {

        onCompleted: () => {

            setshowGithubCheckError(false)
        }
        , onError: () => {
            setshowGithubCheckError(true)
        }
    });

    const { loading: loadlist } = useQuery(FETCH_USER_PAT, {
        onCompleted: (data) => {
            if ((data && data.getUserPAT && data.getUserPAT[0] && data.getUserPAT[0].patToken)) {
                const octokit = new Octokit({
                    auth: `token ${data && data.getUserPAT && decodeAes(data.getUserPAT[0].patToken)}`,
                });
                setshowGithubFetchLoading(true)
                async function requestUser() {
                    try {
                        const resp = await octokit.request('Get /user/repos', {});
                        setlistofrepo(resp.data.filter((e) => {
                            return !e.private
                        }))
                        setshowGithubFetchLoading(false)
                    }
                    catch (error) {
                        console.error('Error:', error);
                        setshowGithubFetchLoading(false)
                    }

                }


                requestUser();

            }
        }
    });

    const renderRepoList = () => {
        return (
            <Box>
                <Typography sx={{ mt: 1 }}>{listofrepo.length} public repositories</Typography>
                <List dense>
                    {listofrepo &&
                        listofrepo
                            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                            .map((e) => {
                                const lastUpdated = moment(new Date(e.updated_at), "YYYY MM DD hh:mm:ss").fromNow();
                                return (
                                    <Tooltip key={e.id} arrow title={e.description}>
                                        <ListItemButton
                                            onClick={() => {
                                                setgithubBranch(e.default_branch);
                                                setgithubLink(e.html_url);
                                                setselectedfromgithublist(true);
                                                setshowGithubCheckError(false);
                                            }}
                                            secondaryAction={
                                                <IconButton edge="end">
                                                    <FiArrowUpLeft />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemIcon>
                                                <AiOutlineGithub size={20} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={e.full_name}
                                                secondary={
                                                    <>
                                                        Last updated {lastUpdated} • {e.private ? "Private" : "Public"}{" "}
                                                        {e.private ? <FaLock size={10} style={{ marginLeft: "5px" }} /> : <BiWorld size={10} style={{ marginLeft: "5px" }} />}
                                                    </>
                                                }
                                            />
                                        </ListItemButton>
                                    </Tooltip>
                                );
                            })}
                </List>
            </Box>
        );
    };

    const renderGithubDetails = () => {
        return (
            <ListItem
                secondaryAction={
                    <IconButton onClick={() => {
                        setgithubLink("");
                        setselectedfromgithublist(false);
                    }}>
                        <Close />
                    </IconButton>
                }
            >
                <ListItemIcon>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        badgeContent={<CheckCircle style={{ color: "green", fontSize: "15px" }} />}
                    >
                        <AiOutlineGithub style={{ fontSize: "30px" }} />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={githubLink.split("https://github.com/")[1]} secondary={githubBranch && `${githubBranch} branch`} />
            </ListItem>
        );
    };

    return (
        <Box sx={{ mt: 1, minWidth: "400px" }}>
            {selectedfromgithublist ? renderGithubDetails() : (
                <TextField
                    value={githubLink}
                    onChange={handleGithubInputUrl}
                    helperText={gitUrlValidation && "Not Github URL, It should start with 'https://github.com/...' "}
                    error={gitUrlValidation}
                    disabled={isQuickApps || buildLoad || revertApploading || DPLoad || selectedfromgithublist}
                    style={{ marginBottom: "10px" }}
                    fullWidth
                    variant="outlined"
                    placeholder="ex. https://github.com/prose/gatekeeper.git"
                    label="Github link"
                />
            )}

            {githubLink && (
                <Box sx={{ borderRadius: "12px", background: "#f5f5f5" }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ marginBottom: "10px" }}>
                        {gitrepoLoad && <Typography color="primary" sx={{ p: "10px", color: "#111", minWidth: "400px" }}>Checking...</Typography>}
                        {gitrepoErr && showGithubCheckError && !selectedfromgithublist && !gitUrlValidation && (
                            <Typography color="error" style={{ fontSize: "13px", padding: "10px" }}>
                                {gitrepoErr.message}, <b>If your repo is private we cannot access as per now.</b>
                            </Typography>
                        )}
                    </Grid>
                </Box>
            )}

            {!selectedfromgithublist && githubLink && <BranchesList githubUrl={githubLink} setgithubBranch={setgithubBranch} githubBranch={githubBranch} />}

            <Fade in={showGithubFetchLoading}>
                <LinearProgress />
            </Fade>

            {githubLink && !buildLoad && selectedfromgithublist && listofrepo && listofrepo.length > 0 && (
                <Button
                    sx={{ mt: 1 }}
                    onClick={() => {
                        setselectedfromgithublist(false);
                        setgithubLink("");
                        setgithubBranch("main");
                    }}
                    startIcon={<AiOutlineArrowLeft />}
                    fullWidth
                    size="small"
                >
                    Back to list of repositories
                </Button>
            )}

            {githubLink === "" && renderRepoList()}

            {githubLink && (
                <Button
                    sx={{ my: 1 }}
                    disabled={gitrepoErr && showGithubCheckError}
                    fullWidth
                    variant="contained"
                    disableElevation
                    onClick={() => {
                        handleBuildStartBuild();
                        // const zipUrl = githubLink.endsWith(".git")
                        //     ? `${githubLink.slice(0, -4)}/archive/refs/heads/${githubBranch}.zip`
                        //     : `${githubLink}/archive/refs/heads/${githubBranch}.zip`;
                        // setbuildFileUrl(zipUrl);
                        // if (directBuild) {
                        //     handleDirectBuildFileDeploy(zipUrl);
                        // }
                        // setdeploymentType(false);
                    }}
                >
                    Build
                </Button>
            )}
        </Box>
    );
}

export default MonolithGithubDeploymentOption;
