import { gql, useQuery } from '@apollo/client';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from "react-router-dom";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TuneIcon from '@mui/icons-material/Tune';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { logout } from '../../actions/index';
import { supabase } from "../../client";
import NavBar from '../../Containers/NavBar';
import Notification from "./Notification";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { WorkloadNavBar } from '../Deployments/Overview/Main';
import { FeedbackOutlined } from '@mui/icons-material';
import SwitchOrgs from '../Org/SwitchOrgs';
import { Box, Grid } from '@mui/material';
import SwitchLocation from '../Location/SwitchLocation';
import { CustomTooltip } from './Organization';
import { BiMoon } from 'react-icons/bi';
import { BsSunFill } from 'react-icons/bs';
import { CONFIG_APP_BASE_COMPANY } from '../../configApp';

export const USER_DATA = gql`
  query UserInfo{
  getUserById{
    id
    email
    firstName
    lastName
    profileImageUrl
  }
}`

export const USER_ACTIVITY = gql`
query UserActivityQuery($first: Int!){
    userActivities(first: $first) {
      id
      type
      userId
      activities
      message
      isRead
      createdAt
    }
}`

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} placement="left" arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip} `]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        color: "#000",
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "x-large",
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    subTitle: {
        color: "grey",
        fontSize: "12px",
    },
    titledark: {
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "x-large",
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    maintext: {
        color: theme.palette.text.primary,
    },
    // search: {
    //     position: 'relative',
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: alpha(theme.palette.common.white, 0.15),
    //     '&:hover': {
    //         backgroundColor: alpha(theme.palette.common.white, 0.25),
    //     },
    //     marginRight: theme.spacing(2),
    //     marginLeft: 0,
    //     width: '100%',
    //     [theme.breakpoints.up('sm')]: {
    //         marginLeft: theme.spacing(3),
    //         width: 'auto',
    //     },
    // },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        margin: "5px",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        textTransform: "capitalize",
        display: 'flex',
        justifyContent: "flex-start",
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    appbar: {
        boxShadow: "none",
        background: "transparent",
        color: "#111",
    },
    appbardark: {
        boxShadow: "none",
        background: "#181818",
        color: "#111",
    },
}));

function useSearchQuery() {
    return new URLSearchParams(useLocation().search);
}

export function RenderTitle({ value, name }) {

    const orgs = useSearchQuery().get("o");

    if (value[value.length - 1] === "location") {
        return (<>Locations</>)
    }

    if (value[value.length - 3] === "location") {
        return (<Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <SwitchLocation />
        </Grid>)
    }



    if (value[value.length - 3] === "deployments") {
        return (<WorkloadNavBar />)
    }



    if (value[value.length - 1] === "account") {
        return (<>Settings</>)
    }

 

    if (value[value.length - 1] === "teamboard") {
        var today = new Date()
        var curHr = today.getHours()
        if (curHr < 12) {
            return (<>Good Morning{name && `, ${name.getUserById.firstName}!`}</>)
        } else if (curHr < 18) {
            return (<>Good Afternoon{name && `, ${name.getUserById.firstName}!`}</>)
        } else {
            return (<>Good Evening{name && `, ${name.getUserById.firstName}!`}</>)
        }

    }
    if (value[value.length - 1].startsWith("orgs?")) {
        return <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            {orgs.toString().toLowerCase()}
            <SwitchOrgs orgId={orgs} />
        </Grid>
    }

    if (value[value.length - 1].startsWith("databases")) {
        return <>Databases</>
    }

    if (value[value.length - 1].startsWith("workloads")) {
        return <>Workloads</>
    }
    return (<>
        {decodeURI(value[value.length - 1])}

    </>)


}


function Nav(props, { children }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    let title = window.location.href.split("/")
    let history = useHistory();
    const isDarkTheme = useTheme().palette.mode === 'dark';


    const handleProfile = () => {
        history.push("/settings");
        setAnchorEl(null)
    }
    async function signOut() {
        await supabase.auth.signOut()
    }

    const handleLogOut = () => {
        signOut()
        props.logout()
        setAnchorEl(null)
        history.push("/");
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {

    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const { error: err, data: userdata } = useQuery(USER_DATA);


    useEffect(() => {

    }, [title]);


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            style={{ boxShadow: "none" }}
        >
            {!err && <div>
                <div style={{ textAlign: "center", marginBottom: "1px", minWidth: "220px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Avatar style={{ height: "55px", width: "55px", marginTop: "10px", marginBottom: "0px" }} alt={userdata && userdata.getUserById.firstName} src={userdata && userdata.getUserById.profileImageUrl} />
                    </div>

                    <br /> <h3 style={{ color: "#1a8bc4", margin: "0px", textTransform: "capitalize" }}> {userdata && userdata.getUserById.firstName} {userdata && userdata.getUserById.lastName}</h3>
                    {/* <span style={{ fontSize: "1rem",padding:"10px" }}>
                        {userdata && userdata.getUserById.email}
                    </span> */}
           


                </div>
                <Divider component="li" />

                <Link to="/settings/account" className={classes.maintext} style={{ textDecoration: "none" }}>
                    <MenuItem onClick={handleProfile}>

                        <ListItemIcon>
                            <Badge variant='dot' color="error" invisible={!(userdata && userdata.getUserById.firstName === "Nife" && userdata && userdata.getUserById.lastName === "User")}>
                                <TuneIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={<Typography>Profile</Typography>} secondary={userdata && userdata.getUserById.firstName === "Nife" && userdata && userdata.getUserById.lastName === "User" && <Typography variant='body2'>Complete your profile</Typography>} />
                    </MenuItem>
                </Link>



                <MenuItem onClick={() => { window.open(localStorage.getItem("customer_Stripe_Id") ? localStorage.getItem("customer_Stripe_Id") : "/plans", '_blank') }}>
                    <ListItemIcon>
                        <CreditCardOutlinedIcon />
                    </ListItemIcon>
                    Billing
                </MenuItem>


                <MenuItem onClick={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLScv6N9CH88PPvhL9gVLBed_gM-g4HMbg__bj26Jx5CW1O7BTw/viewform?usp=sharing") }}>
                    <ListItemIcon>
                        <FeedbackOutlined />
                    </ListItemIcon>
                    Feedback
                </MenuItem>



                <MenuItem onClick={() => { window.open('https://docs.nife.io/', '_blank') }}>
                    <ListItemIcon>
                        <HelpOutlineIcon />
                    </ListItemIcon>
                    Help
                </MenuItem>
            </div>}

            <MenuItem onClick={handleLogOut} style={{ color: "#ff7a7a" }}>
                <ListItemIcon>
                    <ExitToAppIcon style={{ color: "#ff7a7a" }} />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    );


    return (
        <>


            <div className={classes.grow}>
                <AppBar className={isDarkTheme ? classes.appbardark : classes.appbar} position="static">
                    <Toolbar>

                        <Typography className={isDarkTheme ? classes.titledark : classes.title} variant="h6" noWrap>

                            <RenderTitle value={title} name={userdata && userdata} />

                        </Typography>


                        <div className={classes.sectionMobile}>
                            <NavBar />
                            <Typography sx={{ pl: 2 }} variant="h6" noWrap>
                                <RenderTitle value={title} name={userdata && userdata} />

                            </Typography>
                        </div>

                        <div className={classes.grow} />

                        <div className={classes.search}>

                        </div>
                        <div className={classes.sectionDesktop}>
                            <CustomTooltip placement="left" arrow title="Powered by Nife">
                                <a href="https://nife.io/" target="_blank" rel="noreferrer">
                                    {isDarkTheme ?
                                        <img loading="lazy" style={{ borderLeft: "0px solid rgb(225, 225, 225)", margin: "0px", padding: "10px", textAlign: "center" }} alt="Nife" src={"/OikosDark.svg"} height="60px" width="auto" /> :
                                        <img loading="lazy" style={{ borderLeft: "0px solid rgb(225, 225, 225)", margin: "0px", padding: "10px", textAlign: "center" }} alt="Nife" src={CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg"}  height="60px" width="auto" />}
                                </a>
                            </CustomTooltip>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <CustomTooltip title={`Enable ${localStorage.getItem("mode") === "dark" ? "light" : "dark"} mode`}>
                                    <IconButton
                                        sx={{ p: "5px", ml: 1 }}

                                        onClick={() => {
                                            if (localStorage.getItem("mode") === "dark") {
                                                localStorage.setItem("mode", "light")
                                            }
                                            else {
                                                localStorage.setItem("mode", "dark")
                                            }
                                            window.location.reload()
                                        }}
                                    >
                                        {localStorage.getItem("mode") === "dark" ? <BsSunFill style={{ color: "grey" }} /> : <BiMoon style={{ color: "grey" }}/>}
                                    </IconButton>
                                </CustomTooltip>
                            </Box>
                            <Notification />
                            <IconButton
                                edge="end"
                                color="primary"
                                component="span"
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                style={{ padding: "5px", transition: "none", backgroundColor: 'transparent' }}
                                size="large">
                                <Badge variant='dot' color="error" invisible={!(userdata && userdata.getUserById.firstName === "Nife" && userdata && userdata.getUserById.lastName === "User")}>
                                    <Avatar style={{ height: "25px", width: "25px" }} alt={userdata && userdata.getUserById.firstName} src={userdata && userdata.getUserById.profileImageUrl} />
                                </Badge>
                            </IconButton>
                        </div>


                    </Toolbar>
                </AppBar>
                {renderMenu}

            </div>
            {props.children}
        </>
    );
}

const mapStateToProps = ({ authReducer }) => {
    return { authReducer }
}
export default connect(mapStateToProps, { logout })(Nav)