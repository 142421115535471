import React, { useState } from 'react';
import { Grid, Paper, Button, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FaCircle, FaDocker, FaServer } from 'react-icons/fa';
import { BsDatabase, BsThreeDots } from 'react-icons/bs';
import { Delete } from '@mui/icons-material';
import ServiceActionsMenu from './monolithServiceActions';





const ServiceDetails = ({ service, refetchMonolithData,ipAddress }) => {
    // Prepare columns for the DataGrid
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton style={{ padding: "0px 0px 0px 0px", marginRight: "10px" }} >
                            <FaServer />
                        </IconButton>
                        {cellValues.value}
                    </>
                );
            }

        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    sx={{
                        background:  params.value === 'active' ? 'green' : 'red',
                        color: 'white',
                        px:1,
                        fontSize:"0.8rem",
                        borderRadius:"24px",
                        textTransform: "capitalize",
                    }}
                >
                    {params.value || 'N/A'}
                </Typography>
            ),
        },
        {
            field: 'image',
            headerName: 'Image',
            width: 250,
            renderCell: (params) => (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <FaDocker style={{ marginRight: '8px' }} />
                    {params.value || 'N/A'}
                </Typography>
            ),
        },
        {
            field: 'internalport',
            headerName: 'Ports',
            width: 150,
            renderCell: (params) => {
                const externalport = params.row.externalport;
                const internalport = params.value
                return (
                    (externalport && internalport) ? (
                        <Grid container direction="column" spacing={0}>
                            <Grid item>
                                <Typography sx={{ fontSize: "12px" }}>
                                    {externalport} and {internalport}
                                </Typography>
                            </Grid>

                        </Grid>
                    ) : (
                        'N/A'
                    )
                );
            },
        },
        {
            field: 'volumes',
            headerName: 'Volumes',
            width: 150,
            renderCell: (params) => (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <BsDatabase style={{ marginRight: '8px' }} />
                    {params.value || 'N/A'}
                </Typography>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <ServiceActionsMenu ipAddress={ipAddress} refetchMonolithData={refetchMonolithData} serviceName={params.row.name}/>
            ),
        },
    ];

    // Prepare rows data for the DataGrid


    return (
        <Grid item md={12} sx={{width: "100%"}}>

            <Paper variant="outlined" sx={{ borderRadius: '12px', boxShadow: 'rgba(17, 12, 46, 0.05) 0px 30px 50px 05px', padding: '10px', marginTop: '15px' }}>


                <div>
                    <DataGrid
                        dense
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        pageSize={10}
                        style={{ border: '2px' }}
                        autoHeight={true}
                        density="compact"
                        scrollbarSize={1}
                        rowHeight={70}
                        rows={service} columns={columns}
                    />
                </div>
            </Paper>
        </Grid>
    );
};

export default ServiceDetails;
