import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { useSnackbar } from 'notistack'; // Import the useSnackbar hook

import LogsDialog from './monolithActionLogsDialog'; // Import LogsDialog

const ServiceActionsMenu = ({ serviceName, refetchMonolithData, ipAddress }) => {
    const { enqueueSnackbar } = useSnackbar(); // Get enqueueSnackbar function for notifications
    // State for the anchor element and menu open state
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    // States for the dialogs
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dataDialogOpen, setDataDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');

    // Function to handle opening the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    // Function to handle closing the menu
    const handleClose = () => {
        setOpen(false);
    };

    // Function to handle menu item clicks
    const handleMenuItemClick = (option) => {
        console.log(option);

        // Logic for opening different dialogs based on the option
        if (option === 'Delete') {
            setDeleteDialogOpen(true); // Open delete confirmation dialog
        } else if (option === 'Logs') {
            setDialogTitle('Logs'); // Set title to 'Logs'
            setDataDialogOpen(false); // Close the generic data dialog

            // Open the LogsDialog for logs data
            setLogsDialogOpen(true); // Set the logs dialog open state
        }

        handleClose(); // Close the menu
    };

    // Handle delete confirmation
    const handleDeleteConfirmation = async () => {
        // Assuming you have access to the serviceName and ipAddress variables
        const serviceData = {
            serviceName: serviceName,
            ipAddress: ipAddress, // replace this with the actual IP address or get it from somewhere
        };

        try {
            const response = await fetch('https://getuptime.clb2.nifetency.com/monolithservices/' + serviceName, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(serviceData),
            });

            if (response.ok) {
                // Notify success and close the dialog
                enqueueSnackbar(`Service ${serviceName} deleted successfully!`, { variant: 'success' });
                // Close the dialog and refresh the data
                handleClose();
                refetchMonolithData();
            } else {
                enqueueSnackbar('Failed to delete service.', { variant: 'error' });
            }
        } catch (error) {
            console.error("Error deleting service:", error);
            enqueueSnackbar('Error deleting service. Please try again later.', { variant: 'error' });
        }

        setDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    // Handle data dialog close
    const handleDataDialogClose = () => {
        setDataDialogOpen(false);
    };

    // New state to control MetricsDialog and LogsDialog
    const [metricsDialogOpen, setMetricsDialogOpen] = useState(false);
    const [logsDialogOpen, setLogsDialogOpen] = useState(false);

    // Handle MetricsDialog close
    const handleMetricsDialogClose = () => {
        setMetricsDialogOpen(false);
    };

    // Handle LogsDialog close
    const handleLogsDialogClose = () => {
        setLogsDialogOpen(false);
    };

    return (
        <div>
            {/* IconButton to trigger menu */}
            <IconButton onClick={handleClick}><BsThreeDots /></IconButton>

            {/* MUI Menu Component */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick('Logs')}>Logs</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem>
            </Menu>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this item? This action cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Logs Dialog */}
            <LogsDialog
                ip={ipAddress}
                open={logsDialogOpen}
                onClose={handleLogsDialogClose}
                serviceName={serviceName}
            />

            {/* Data Dialog (for Logs) */}
            <Dialog
                open={dataDialogOpen}
                onClose={handleDataDialogClose}
                maxWidth="md" // Medium size dialog
                fullWidth
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {/* Displaying fetched data */}
                    <p>{dialogContent}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDataDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ServiceActionsMenu;
