import { gql, useMutation, useQuery } from '@apollo/client';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FiCpu } from "react-icons/fi";
import { GrStorage } from "react-icons/gr";
import { RiArrowGoBackFill } from "react-icons/ri";
import CreateNewApp, { Transition } from "../../Home/CreateNewApp/Main";
import { CONFIG_DEFINITION } from "../../Home/CreateNewApp/StepTwo";
import { HISTORY } from "../Activity/ActivityTable";
import { APP } from "../Overview/Main";

import { AppBar, Box, Fade, LinearProgress, TextField, Toolbar } from '@mui/material';
import { useRouteMatch } from "react-router-dom";
import Redeploy from "./Redeploy";

import { BiWorld } from "react-icons/bi";
import UpgradePlan from '../../../Containers/UpgradePlan';
import { CustomTooltip } from '../../Settings/Organization';
import ConfigDownload from './ConfigDownload';
import { BsDatabaseFill } from 'react-icons/bs';
import GitHubTimeline from './GithubTimeline';
import axios from 'axios';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { CONFIG_APP_UPTIME_METRICS_BACKEND_URL, CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../../configApp';


export const REVERT_APP = gql`
mutation RevertAppMutation( $appName: String!,$appId: String!, $internalPort:String!, $externalPort: String!,$image: String!,  $archiveUrl: String, $version: Int,  $envMapArgs: [String]){
  updateConfigApps(input: { appId: $appId,externalPort: $externalPort,archiveUrl : $archiveUrl, image: $image, appName: $appName, internalPort: $internalPort,version: $version,  envMapArgs: $envMapArgs }){
    appId
    appName
    internalPort
    externalPort
  }
}
`

export const ELB_URL = gql`
query ElbUrlQuery($appName: String){
  getElbUrl(input: {appName:$appName}) {
    elbUrl
  }
}
`

export const VOLUME_DETAILS = gql`
  query VolumeDetailsQuery($appId: String){
  getVolumeByAppId(appId:$appId ) {
    appId
    accessMode
    name
    path
    size
    volumeTypeId

  }
}`

export const ADD_TEMPLATE = gql`
mutation addTemplateMutation( $appName: String!,$name: String!, $isActive:Boolean){
  appTemplate(input: { name: $name,isActive: $isActive, appName: $appName})
}`



const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    height: theme.spacing(16),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paper: {
    backgroundColor: "whitesmoke",
    padding: theme.spacing(2),
    width: "100%",
  },
  table: {
    fontFamily: "Rubik"
  },
}));



const CONFIG_DETAILS = gql`
query ConfigQuery($name: String!){
  app(name: $name){
    id
    name
    status
    deployed
    hostname
    appUrl
    imageName
    port
    sourceUrl
    builds {
      nodes{
          image
        }
        
      }
    
    envArgs
  }
}`

const DB_DETAILS = gql`
query DbDeatailQuery($secretId: String!){
  getSecretByRegistryId(secretId: $secretId) {
    id
    organizationId
    name
    registryType
    userName
    passWord
    Url
    keyFileContent
    registryName
    isActive
    secretType
  }
}`

export default function Main(props) {


  const role = localStorage.getItem("role")
  const match = useRouteMatch();
  const classes = useStyles();
  const [redployed, setredployed] = React.useState(false)
  const [openInfo, setOpenInfo] = React.useState(true);
  const [buildType, setbuildType] = React.useState("");
  const [def, setdef] = React.useState({})
  var definitionMap = new Map();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [openDomain, setopenDomain] = React.useState(false);
  const [envArgsValue, setenvArgsValue] = React.useState("")



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickRevert = (e) => {
    revertApp({ variables: { version: e.version, envMapArgs: [], appName: match.params.id, appId: appdata && appdata.app.id, image: e.image, externalPort: "80", internalPort: e.port.toString() } })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDomain = () => {
    setopenDomain(false);
  };

  const handleClickOpenDomain = () => {
    setopenDomain(true);
  };



  const { loading, error, data, refetch } = useQuery(CONFIG_DETAILS,
    {
      onCompleted: (data) => {
        if (data && data.app.envArgs) {
          setenvArgsValue(data.app.envArgs)
        }
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      },
      variables: { name: match.params.id }
    }
  );
  const { data: appdata, error: apperr, loading: appload } = useQuery(APP, {
    variables: {
      name: match.params.id
    }, notifyOnNetworkStatusChange: true,
    onCompleted: () => {
    }
  })

  const { data: dbdata, error: dberr, loading: dbload } = useQuery(DB_DETAILS, {
    variables: {
      secretId: appdata && appdata.app.secretRegistryId ? appdata.app.secretRegistryId : ""
    }
  })



  const { data: urldata, error: urlerr, loading: urlload } = useQuery(ELB_URL, {
    variables: {
      appName: match.params.id
    }
  })


  const { loading: load, error: err, data: history } = useQuery(HISTORY, { variables: { name: match.params.id } });

  const { loading: loadvolume, error: errvolume, data: datavolume } = useQuery(VOLUME_DETAILS, { variables: { appId: match.params.id } });




  const { data: configdata, error: configerr, loading: configload } = useQuery(CONFIG_DEFINITION, {
    variables: {
      name: props.name
    }, notifyOnNetworkStatusChange: true,
    onCompleted: () => {

      { configdata.app.config && definitionMap.set('definition', configdata.app.config.definition) }
      setdef(definitionMap.get("definition"))
    }
  })

  const [revertApp, { data: editdata, loading: editloading, error: editerror }] = useMutation(REVERT_APP, {
    refetchQueries: [HISTORY],
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })

    },
    onCompleted: () => {
      setOpen(false);
      enqueueSnackbar("Successfully Completed!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });

  const [customDomainName, setcustomDomainName] = useState("");

  const handleCustomDomain = () => {
    try {
      const res = axios.post(
        `${CONFIG_APP_UPTIME_METRICS_BASE_URL}/sync-dns-record-app/`, // Replace with your API endpoint
        {
          customDomainName: customDomainName,        // Send the data as JSON
          urldata: urldata ? urldata.getElbUrl.elbUrl : "", // Another field example
        },
        {
          headers: {
            'Content-Type': 'application/json', // Ensuring the data is sent as JSON
          },
        }
      );
      if (res) {
        enqueueSnackbar("Successfully synced!", {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }


    } catch (err) {
      // Handle error
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  }


  const [addTemplte] = useMutation(ADD_TEMPLATE, {
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })

    },
    onCompleted: () => {

      enqueueSnackbar("Successfully Added!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });



  useEffect(() => {
    refetch()

  }, []);



  let imageName = history && history.app.releases.nodes && history.app.releases.nodes.find(o => o.status === "active")

  const redployedfun = () => {
    setredployed(true)
  }

  if (loading) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }

  if (load) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }


  if (apperr && apperr.message !== "sql: Rows are closed") return `Error: ${apperr.message}`;
  if (error && error.message !== "sql: Rows are closed") return `Error! ${error.message}`;


  return (
    <div className={classes.root} style={{ margin: "25px" }}>

      {!imageName && (
        <div>
          {openInfo && <Paper className={classes.paper}>
            <Grid container>

              <Grid item xs={12} md={9} lg={9}>

                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  What are Deployments ?
                </Typography>
                <Typography style={{ color: "#585779d1" }}>
                  Deployments are application capabilities that are provisioned over a distributed network. Once a deployment has been created, services and addons can be added to it.The deployment becomes Active once it has been deployed.
                </Typography>
                <a target='_blank' href={"https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-Create"}>Read more about deployments.</a>

                <br />
                <Button onClick={() => { setOpenInfo(false) }} style={{ borderColor: "#bbb", borderWidth: "thin", borderStyle: "solid", margin: "10px auto" }} > Got it, Dismiss</Button>
              </Grid>
              <Grid item xs={false} md={3} lg={3}>
                <img src="https://res.cloudinary.com/dfxupfsej/image/upload/v1626058890/page6_ygxhm3.png" height="150px" width="150px" alt="mo image" />
              </Grid>

            </Grid>

          </Paper>}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"

          >
            <div>
              <h3> No data</h3>
              <div style={{ color: "grey" }}> App not deployed</div>
            </div>
            <CreateNewApp steptwo={true} appName={match.params.id} />
          </Grid>
        </div>)}


      {imageName && imageName.image && (
        <div style={{ marginTop: "10px" }}>


          <Typography className={classes.heading}>







            <Grid item spacing={0}>
              <Typography component="h2" variant="h6" gutterBottom style={{ marginTop: "20px", fontWeight: "bold" }}>
                Services
              </Typography>
            </Grid>
            <Grid>
              <Typography component="h2" variant="subtitle1" style={{ color: "grey" }} >
                Services represent the distributed technical capabilities to be deployed. <a target='_blank' href={"https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-management/Configuration"}>Read more</a>
              </Typography>
            </Grid>


          </Typography>


          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >

            <div>
              <IconButton aria-label="delete" size="large">
                <CheckCircleIcon style={{ color: "green" }} />
              </IconButton>
              <Typography style={{ marginBottom: "10px" }} variant="button"> {!redployed ? "Active and healthy  " : "Redeployed Succesfully"}</Typography>
            </div>
            <div>



              {datavolume && datavolume.getVolumeByAppId && datavolume.getVolumeByAppId[0].size ? <Typography sx={{ fontWeight: "bold" }}>Cannot redeploy app with volume</Typography> :
                <Redeploy name={match.params.id} imageName={imageName && imageName.image} redployedfun={redployedfun} data={appdata && appdata.app.id} replicas={appdata && appdata.app.replicas} orgId={appdata && appdata} />
              }
            </div>

          </Grid>


          <Divider style={{ marginBottom: "15px" }} />

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"

          >
            <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Configuration  details
              <CustomTooltip title="Click to Save this Configuration details as a Template">
                <IconButton style={{ marginLeft: "5px" }} onClick={() => addTemplte({ variables: { appName: match.params.id, name: `${match.params.id} v${imageName && imageName.version}`, isActive: true } })}>
                  <BookmarkAddIcon style={{ fill: "#5d86f9" }} /></IconButton>
              </CustomTooltip>
            </Typography>



            <div>

              <ConfigDownload />
            </div>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>

                  <TableCell align="left" style={{ fontWeight: "bold" }}>Image Name</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>Internal Port</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>External Port</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>Build Type</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>Replica</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>Routing Policy</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>Version</TableCell>
                  {imageName && imageName.image && !(imageName.image.startsWith("mysql") || imageName.image.startsWith("postgres")) && <TableCell align="center" style={{ fontWeight: "bold" }}>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow>

                  <TableCell align="left">{imageName && imageName.image}</TableCell>
                  <TableCell align="center">{appdata && appdata.app.config && appdata.app.config.definition.services[0].internal_port.toString()}</TableCell>
                  <TableCell align="center">{appdata && appdata.app.config && appdata.app.config.definition.services[0].external_port.toString()}</TableCell>
                  <TableCell align="center">{appdata && appdata.app.config && appdata.app.config.build ? appdata.app.config.build.builder : "Docker File"}</TableCell>
                  <TableCell align="center">{appdata && appdata.app.replicas && appdata.app.replicas}</TableCell>
                  <TableCell align="center">{appdata && appdata.app.config && appdata.app.config.definition.services[0].routing_policy}</TableCell>

                  <TableCell align="center"> {imageName && imageName.version}</TableCell>

                  {imageName && imageName.image && !(imageName.image.startsWith("mysql") || imageName.image.startsWith("postgres")) && <TableCell align="right">
                    <Button disabled={role === "Viewer"} endIcon={<RiArrowGoBackFill />} color="primary" onClick={handleClickOpen}>
                      Revert
                    </Button>


                  </TableCell>}
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "15px" }}>
            Custom Domain
          </Typography>

          {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).customDomain) ?
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"

            >

              <Typography component="h2" variant="subtitle1" >
                Please click on the button to add your custom domain.
              </Typography>

              <Button disabled={appdata && appdata.app.status === "Suspended" || role === "Viewer"} startIcon={<BiWorld />} onClick={handleClickOpenDomain} variant="contained">Add Custom Domain</Button>
            </Grid>

            : <UpgradePlan />}



          <Grid container style={{ marginTop: "20px" }} spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
              <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Resource Configuration
              </Typography>

              <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Resources</TableCell>
                      <TableCell align="left">Request Requirement</TableCell>
                      <TableCell align="left">Limit Requirement</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell><IconButton><FiCpu /></IconButton> CPU</TableCell>
                      <TableCell> {appdata && appdata.app.config && appdata.app.config.definition.services[0].requests && appdata.app.config.definition.services[0].requests.cpu ? appdata && appdata.app.config && appdata.app.config.definition.services[0].requests && appdata.app.config.definition.services[0].requests.cpu : "Default"}</TableCell>
                      <TableCell> {appdata && appdata.app.config && appdata.app.config.definition.services[0].limits && appdata.app.config.definition.services[0].limits.cpu ? appdata && appdata.app.config && appdata.app.config.definition.services[0].limits && appdata.app.config.definition.services[0].limits.cpu : "Default"}</TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell> <IconButton><BsDatabaseFill /></IconButton>Memory</TableCell>
                      <TableCell> {appdata && appdata.app.config && appdata.app.config.definition.services[0].requests && appdata.app.config.definition.services[0].requests.memory ? appdata && appdata.app.config && appdata.app.config.definition.services[0].requests && appdata.app.config.definition.services[0].requests.memory : "Default"}</TableCell>
                      <TableCell> {appdata && appdata.app.config && appdata.app.config.definition.services[0].limits && appdata.app.config.definition.services[0].limits.memory ? appdata && appdata.app.config && appdata.app.config.definition.services[0].limits && appdata.app.config.definition.services[0].limits.memory : "Default"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>



            {data && data.app.envArgs !== "" &&
              <Grid item xs={12} sm={12} lg={6}>
                <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
                  Environment Args
                </Typography>



                {data && data.app.envArgs === "" ? ("No Environment Args") : (
                  <>


                    {/* <Typography vairant="h5" style={{ marignTop: "10px" }}>Environment Args</Typography> */}

                    <TableContainer component={Paper}>

                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Value</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>


                          {data && envArgsValue && Object.entries(JSON.parse(envArgsValue)).filter(([key, value]) => `${key}`[value] !== 'undefined').map(([key, value], idx) =>
                            <TableRow key={idx}>
                              <TableCell component="th" scope="row">{key}</TableCell>
                              <TableCell component="th" scope="row">{value}</TableCell>
                            </TableRow>
                          )}

                        </TableBody>
                      </Table>
                    </TableContainer></>)}
              </Grid>}

            {dbdata && dbdata.getSecretByRegistryId.registryType && dbdata.getSecretByRegistryId.registryType !== "PAT" && appdata && appdata.app.config && appdata.app.config.build && appdata.app.config.build.builder === "DB" &&
              <Grid item xs={12} sm={12} lg={6}>
                <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
                  Database Details
                </Typography>




                <>




                  <TableContainer component={Paper}>

                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>



                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Database Type</b></TableCell>
                          <TableCell component="th" scope="row">{dbdata.getSecretByRegistryId.registryType}</TableCell>
                        </TableRow>

                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Database Name</b></TableCell>
                          <TableCell component="th" scope="row">{dbdata.getSecretByRegistryId.name}</TableCell>
                        </TableRow>

                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>User Name</b></TableCell>
                          <TableCell component="th" scope="row">{dbdata.getSecretByRegistryId.userName}</TableCell>
                        </TableRow>

                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Password</b></TableCell>
                          <TableCell component="th" scope="row">**********</TableCell>
                        </TableRow>


                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            }


            {datavolume && datavolume.getVolumeByAppId && datavolume.getVolumeByAppId[0].size &&
              <Grid item xs={12} sm={12} lg={6}>
                <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold", fontFamily: "Rubik" }}>
                  Volume Details
                </Typography>
                <>

                  <TableContainer component={Paper}>

                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Volume Name</b></TableCell>
                          <TableCell component="th" scope="row">{datavolume.getVolumeByAppId[0].name}</TableCell>
                        </TableRow>
                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Volume Path</b></TableCell>
                          <TableCell component="th" scope="row">{datavolume.getVolumeByAppId[0].path}</TableCell>
                        </TableRow>



                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Volume Access Mode</b></TableCell>
                          <TableCell component="th" scope="row">{datavolume.getVolumeByAppId[0].accessMode}</TableCell>
                        </TableRow>

                        <TableRow>

                          <TableCell component="th" scope="row"><b style={{ color: "grey" }}>Volume Size</b></TableCell>
                          <TableCell component="th" scope="row">{datavolume.getVolumeByAppId[0].size}Gi</TableCell>
                        </TableRow>


                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            }

          </Grid>

          <Dialog
            open={openDomain}
            onClose={handleCloseDomain}
            maxWidth={"md"}
            fullWidth
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-image"
          >

            {appdata && appdata.app.config && (appdata.app.config.definition.services[0].external_port === 80 || appdata.app.config.definition.services[0].external_port === 443) ?

              <>

              

                    <DialogTitle id="alert-dialog-title">
                      Add Custom Domain for app
                    </DialogTitle>
                
             
                <DialogContent sx={{ padding: 3 }}>
                


                  <TableContainer sx={{
                    background: "rgb(237, 237, 237)",
                    padding: "20px",
                    borderRadius: "12px",
                    boxShadow: 0,
                    mb: 2,
                  }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="domain DNS records">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', color: '#444' }}>Record Type</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#444' }}>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>CNAME</TableCell>
                          <TableCell sx={{ color: '#1976d2', fontWeight: 'bold', fontFamily: 'monospace' }}>
                            <pre style={{ margin: 0 }}>https://{match.params.id}.clb2.nifetency.com</pre>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>Name</TableCell>
                          <TableCell sx={{ color: '#000', fontWeight: 'bold' }}>
                            <TextField
                              size="small"
                              autoFocus={true}
                              value={customDomainName}
                              onChange={(e) => setcustomDomainName(e.target.value)}
                              label="Enter Your Domain"
                              placeholder='google.com'
                              variant="outlined"
                              required
                              sx={{
                                width: '250px',
                                marginLeft: '10px',
                                marginRight: '10px',
                                borderRadius: '8px',
                              }}
                            />
                            <span style={{ color: "grey" }}>ex. google.com, facebook.com</span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>TTL (optional)</TableCell>
                          <TableCell sx={{ color: '#777', fontWeight: 'bold' }}>doesn't matter</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Typography sx={{ mb: 2, fontSize: '1rem', fontWeight: 'bold' }} variant="subtitle1">
                    <span style={{ color: '#1976d2' }}>Please Note:</span> You need to make sure that the following records are added to your domain's DNS records, Once added click on below button to sync.
                  </Typography>

                  <Button
                    disabled={!customDomainName}
                    onClick={handleCustomDomain}
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: '10px 20px',
                      fontWeight: 'bold',
                    }}
                  >
                    I Configured my dns record, Let's Sync
                  </Button>

                  <Typography variant="body2" sx={{ marginTop: 2, fontSize: '0.9rem', color: '#777' }}>
                    It may take up to 24 hours for domain propagation
                  </Typography>
                </DialogContent>

              </> : <>
                <DialogTitle id="alert-dialog-title">
                  Custom Domain not Available
                </DialogTitle>
                <DialogContent>
                  <Typography sx={{ mb: 2 }} variant='subtitle1'>
                    You can only add custom domain for the apps whose <b>external port is 80 or 443</b> for now, We are working on this feature, For other ports it will be available soon.</Typography>
                </DialogContent>

              </>}
            <DialogActions>

              <Button onClick={handleCloseDomain} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-image"
          >
            {editloading && <LinearProgress />}
            <DialogTitle id="alert-dialog-title">
              {editloading ? <div> Please wait..</div> : "Revert back to previous version"}
            </DialogTitle>
            <DialogContent>
              <TableContainer >
                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).versionControlPanel) ?
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ fontWeight: "bold" }}>

                        <TableCell align="left" style={{ fontWeight: "bold" }}>Image Name</TableCell>

                        <TableCell align="center" style={{ fontWeight: "bold" }}>Version</TableCell>
                        <TableCell align="center" style={{ fontWeight: "bold" }}>Status</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {history && history.app.releases.nodes.map((e, i) => (
                        <Fade
                          key={e.id}
                          in={true}
                          style={{ transformOrigin: '0 0 0' }}
                          {...(true ? { timeout: 200 * i } : {})}
                        >
                          <TableRow>

                            <TableCell align="left">{e.image}</TableCell>

                            <TableCell align="center"> {e.version}</TableCell>
                            <TableCell align="center"> {e.status}</TableCell>
                            <TableCell align="right"> {e.status === "active" ? "Current Version" : <Button disabled={editloading} onClick={() => handleClickRevert(e)}>
                              Activate
                            </Button>}
                            </TableCell>
                          </TableRow>
                        </Fade>
                      ))}



                    </TableBody>
                  </Table>
                  : <UpgradePlan />}
              </TableContainer>
            </DialogContent>
            <DialogActions>

              <Button disabled={editloading} onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>





          <br />




        </div>


      )
      }




    </div >
  );
}

