import { gql, useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import Moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FiBox } from "react-icons/fi";
import { Redirect, useHistory } from 'react-router-dom';
import DailogSettngs from "../Deployments/AppSettings/DailogSettngs";
import CreateNewApp from "./CreateNewApp/Main";

import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Fade, LinearProgress, Skeleton, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FaCheckCircle } from 'react-icons/fa';
import { IoIosPeople, IoMdPeople } from 'react-icons/io';
import { SiWheniwork } from 'react-icons/si';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import LogTrigger from '../../LogTrigger';
import { DELETE_APP } from '../Deployments/AppSettings/DeleteApp';
import EmptyPageRenderCustom from '../NewPages/EmptyPageRenderCustom';
import { USER_ACTIVITY } from '../Settings/Nav';
import { CustomTooltip } from '../Settings/Organization';
import { APPS } from './DeploymentTable';
import UserDetailsTooltip from './UserDetailsTooltip';
import AppOpenInLink from './AppOpenInLink';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    background: "linear-gradient(0deg,#4e73df 10%,#618aff 100%)",
    color: "#fff",
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    borderRadius: "12px",
    margin: "10px 24px 24px 24px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 05px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  headline: {
    fontSize: "18px",
    margin: "10px"
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#5d86f9'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

}));

const SupportedAppsList = [
  {
    "name": "Python",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-4.png"
  },
  {
    "name": "Docker",

    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-1.png"
  },
  {
    "name": "Deno",
    "class":"grayscale(1)",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-2.png"
  },
  {
    "name": "Node Js",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-3.png"
  },
  {
    "name": "Go lang",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-5.png"
  },
  {
    "name": "TypeScript",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-6.png"
  },
  {
    "name": "Ruby",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-7.png"
  },
  {
    "name": "Angular",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Logo-8.png"
  },
  {
    "name": "React",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/react-logo-2048x688.png"
  },
  {
    "name": "elixir",
    "class":"grayscale(1)",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/elixir.png"
  },
  {
    "name": "JavaScript",
    "image": "https://cdn-hafbj.nitrocdn.com/xTFQNaYoUfbLubhDKHySpXAoUWTukgNl/assets/images/optimized/rev-41ae93d/nife.io/wp-content/uploads/2022/08/Unofficial_JavaScript_logo_2.svg.png"
  },
]

export const APP_LIST = gql`
query appsList($region: String, $orgSlug: String){
  apps(type: "", first:62, region:$region , orgSlug:$orgSlug){
    nodes{
      id,
      name,
      deployed,
      status,
      version,
      hostname,
      createdAt,
      imageName,
      workloadManagementId,
      WorkloadManagementName,
      userDetails {
        id
        email
        firstName
        lastName
        profileImageUrl 
        roleId
        userProfileCreated
       }
      builtinType
      regions{
        name
        code
        },
      organization{
        slug
      },
      config{
        definition
      },
    }
  }
}`

export const APP_WL_LIST = gql`
query appsWlList($workloadId: String, $userId: String){
  appsWorkloadIdOrUserRole(workloadId:$workloadId , userId:$userId){
    nodes{
      id,
      name,
      deployed,
      status,
      version,
      hostname,
      createdAt,
      imageName,
      workloadManagementId,
      WorkloadManagementName,
      userDetails {
        id
        email
        firstName
        lastName
        profileImageUrl 
        roleId
        userProfileCreated
       }
      builtinType
      regions{
        name
        code
        },
      organization{
        slug
      },
      config{
        definition
      },
    }
  }
}`


export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const columnsApp = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    align: "left",
    renderCell: (cellValues) => {

      return (
        (
          <div>
            <CustomTooltip arrow placement="top-start" title={<Box sx={{ p: 1 }}>
              App name: <b style={{ textTransform: "capitalize" }}>{cellValues.value}</b>
              <br />
              Status: <b>{cellValues.row.status} {cellValues.row.status === "Active" && "✅"}{cellValues.row.status === "New" && "☑️"}{cellValues.row.status === "Suspended" && "❌"}</b>
              <br />
              Deployed:<b> {cellValues.row.deployed ? "Yes 👍" : "No 👎"}</b>
            </Box>}>
              <Grid
                container
              >
                <Grid item>
                  <FaCheckCircle style={{ marginTop: "10px", marginRight: "10px", fontSize: "18px", color: cellValues.row.status === "Active" ? "green" : (cellValues.row.status === "Suspended" ? "grey" : "rgb(33, 150, 243)") }} />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <div>

                      <Typography component={"div"} sx={{ textTransform: "capitalize", fontSize: "0.9rem", cursor: "pointer", width: "150px", '&:hover': { color: cellValues.row.status === "Active" ? "green" : (cellValues.row.status === "Suspended" ? "grey" : "rgb(33, 150, 243)") } }} noWrap>
                        {cellValues.row.WorkloadManagementName ? <span>
                          {cellValues.value.substr(cellValues.value.indexOf("-") + 1)}<i style={{ color: "#5d86f9", marginLeft: "3px" }}>({cellValues.value.split("-")[0]})</i>
                        </span> : cellValues.value}
                      </Typography>

                      <Typography sx={{ fontSize: "11px", fontWeight: "normal" }}>
                        {cellValues.row.status} {"and "}
                        {cellValues.row.deployed ? "Deployed" : "Not deployed"}</Typography>
                    </div>
                  </Grid>
                </Grid>

              </Grid>
            </CustomTooltip>
          </div>
        )
      )


      // return (
      //   <>
      //     <CustomTooltip arrow placement="top-start" title={cellValues.value.substr(cellValues.value.indexOf("-") + 1)}>
      //       <Box style={{ cursor: "pointer" }}>


      //         <IconButton style={{ padding: "0px", margin: "0px 5px 0px 0px" }} aria-label="delete" size="small">
      //           {<FiBox style={{ color: "gray", margin: "0px" }} />}
      //         </IconButton>
      //         {cellValues.value.substr(cellValues.value.indexOf("-") + 1)}<i style={{ color: "#5d86f9", marginLeft: "3px" }}>({cellValues.value.split("-")[0]})</i>
      //       </Box>
      //     </CustomTooltip>
      //   </>
      // )



    }
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   align: "center",
  //   renderCell: (cellValues) => {
  //     if (cellValues.value == "Active") {
  //       return (<div style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
  //     }

  //     if (cellValues.value == "New") {
  //       return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
  //     }
  //   }
  // },
  { field: "version", headerName: "Version", width: 80, align: "center" },
  {
    field: "WorkloadManagementName",
    headerName: "Workload",
    width: 100,
    align: "left",
    renderCell: (cellValues) => {

      if (cellValues.value) {
        return (<Typography color="primary" variant='subtitle2'><IconButton color='primary' style={{ padding: "0px", margin: "0px 5px 0px 0px" }} aria-label="delete" size="small">
          {cellValues.value ? <SiWheniwork /> : <FiBox style={{ color: "gray", margin: "0px" }} />}
        </IconButton>
          {cellValues.value}</Typography>)

      }
      else {
        return ("No Workload")
      }

    }
  },
  // {
  //   field: "deployed",
  //   headerName: "Deployed",
  //   align: "center",
  //   renderCell: (cellValues) => {
  //     return (
  //       <div>
  //         {cellValues.value == false ? (<GrClose style={{ color: "gray", fontSize: "15px" }} />)
  //           : (<VscCheck style={{ color: "green", fontSize: "20px" }} />)}
  //       </div>);
  //   }
  // },
  {
    field: "organization", headerName: "Organization", width: 150, align: "center", sortable: false,
    renderCell: (cellValues) => {
      return (<div style={{ color: "#3d5066", textTransform: "capitalize" }}><IconButton style={{ padding: "0px", margin: "0px 5px 0px 5px" }}>{cellValues.value.slug === "zoro57" ? <IoMdPeople style={{ fontSize: "18px" }} /> : <IoIosPeople style={{ fontSize: "18px" }} />}</IconButton>{cellValues.value.slug}</div>)
    }
  },
  {
    field: "userDetails",
    headerName: "Created by",
    width: 130,
    align: "left",
    renderCell: (cellValues) => {
      return (
        <UserDetailsTooltip data={cellValues.value}>



        </UserDetailsTooltip>);
    }
  },
  { field: "builtinType", headerName: "Build Type", width: 100, align: "center" },
  {
    field: "hostname", headerName: "Link", align: "center"
    , renderCell: (cellValues) => {

      return (<AppOpenInLink data={cellValues} />);
      return (
        <div
          style={{
            textAlign: "left"
          }}
        >
          {cellValues.value == "nife.io" ? ("Not Deployed") : (
            <Button endIcon={<OpenInNewIcon />}
              disabled={cellValues.row.imageName && cellValues.row.imageName.startsWith("mysql") || cellValues.row.imageName && cellValues.row.imageName.startsWith("postgres")}
              size="small"
              onClick={() => {
                cellValues.row.imageName && cellValues.row.imageName.startsWith("mysql") || cellValues.row.imageName && cellValues.row.imageName.startsWith("postgres") ?
                  window.open(cellValues.value, '_blank')
                  : window.open(cellValues.value, '_blank')
              }}
            >open</Button>)}
        </div>
      );

    }
  },

  {
    field: "action",
    headerName: "Action",
    sortable: false,
    align: "center",
    renderCell: (cellValues) => {
      var name = cellValues.row.name;
      var idOfApp = cellValues.row.id;
      var appDeployed = cellValues.row.deployed;
      var status = cellValues.row.status
      return (<DailogSettngs appStatus={status} appId={name} idOfApp={idOfApp} appDeployed={appDeployed} />);
    }
  },
  {
    field: "createdAt", width: 100, headerName: "Created At", align: "right", renderCell: (cellValues) => {
      var date = Moment(new Date(cellValues.row.createdAt), "YYYY MM DD hh:mm:ss").fromNow();
      return <CustomTooltip arrow placement="top-start" title={date}>
        <Typography sx={{ color: "grey", fontSize: "12px" }}> {date}</Typography>
      </CustomTooltip>
    }
  },

];

export default function AppsTable(props) {
  const classes = useStyles();
  const [searched, setSearched] = React.useState("")
  let history = useHistory();


  const requestSearch = (searchedVal) => {
    setSearched(searchedVal)
    const filteredRows = data.apps.nodes.filter((row) => {
      return row.name.includes(searchedVal);
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const [open, setOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [redirectTo, setRedirectTo] = React.useState(null)
  const [rows, setRows] = React.useState([])
  const [showProgress, setshowProgress] = React.useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  const [openCreateNewApp, setopenCreateNewApp] = React.useState(false);

  const handleCellClick = (param, event) => {
    if ((param.colDef.field !== "action") && (param.colDef.field !== "__check__") && (param.colDef.field !== "hostname")) {
      setRedirectTo(param.row.name)
      history.push(`/deployments/overview/${param.row.name}`)
    }

    if (param.colIndex === 2) {
      event.stopPropagation();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setshowProgress(false);
  };

  const match = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();


  const [deletedAppCount, setdeletedAppCount] = React.useState(0);

  const { loading, error, data, refetch } = useQuery(APP_LIST, {
    variables: {
      region: props.filterLocation ? match.params.id : "", orgSlug: props.filterOrg ? props.orgId : ""
    },
    onCompleted: (data) => {
      // if(data){
      //   setRows(data.apps.nodes);
      // }

    }
  });

  // const  [fetchWlApps, { loading : loadingwl, error : errorwl, data : datawl  }]= useLazyQuery(APP_WL_LIST, {
  //   variables: {
  //     workloadId: props.filterWorkload ? props.filterWorkload : "", userId: props.filterUserId ? props.filterUserId : ""
  //   },
  //   onCompleted: (datawl) => {
  //     if(datawl){
  //       setRows(datawl.appsWorkloadIdOrUserRole.nodes);
  //     }

  //   }
  // });



  const [deleteApp, { loading: delLoad }] = useMutation(DELETE_APP, {
 
    onCompleted: () => {
      setdeletedAppCount(deletedAppCount + 1)
      if (selectedRows.length <= deletedAppCount + 1) {
        refetch({APPS, APP_LIST, USER_ACTIVITY});
        handleClose()
        setSelectedRows([])
        setdeletedAppCount(0)
        enqueueSnackbar(`Successfully Deleted ${deletedAppCount + 1} Apps!`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }
    },
    onError: (err) => {
      handleClose()
      setSelectedRows([])
      setdeletedAppCount(0)
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  })


  const handleDeleteSelectedApps = () => {
    setshowProgress(true);
    if (selectedRows.length >= 0) {
      selectedRows.forEach((item, index) => {
        if (selectedRows[index].regions) {
          selectedRows[index].regions.forEach(element => {
            deleteApp({ variables: { appId: item.name, regionCode: element.code } })
          });
        }
        else {
          deleteApp({ variables: { appId: item.name, regionCode: "" } })
        }
      });


    }
  }



  useEffect(() => {


    refetch();
    document.title = "Apps"
    if (!error && !loading && data && data.apps.nodes) {
      setRows(
        data.apps.nodes.filter((e) => {
          return e.builtinType !== "DB" && e.builtinType !== "FinOps"
        })
      );

    }
  }, [data]);


  if (error) return `Error! ${error.message}`;
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Apps")
  }

  if (data) {
    LogTrigger(`All Apps length - ${data.apps.nodes && data.apps.nodes.length}`, "info", window.location.href, "Apps")
  }
  if (redirectTo) {

    return <Redirect to={`/deployments/overview/${redirectTo}`} />
  }



  return (
    <div>

      <CreateNewApp hideCreateAppButton={true} openCreateNewApp={openCreateNewApp} handleDisableopenCreateNewApp={() => setopenCreateNewApp(false)} />


      {loading ?
        <div>
          <Skeleton style={{ margin: "10px" }} />
          <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
        </div> :
        <div>


          {(rows && rows.length > 0) ?
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                sx={{ mb: 2 }}
              >

                <Box>
                  <TextField
                    value={searched}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searched !== "" &&
                            <IconButton onClick={cancelSearch}>
                              <CloseIcon />
                            </IconButton>}
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}

                    size={"small"}

                    placeholder='Search for Apps'
                    onChange={(e) => requestSearch(e.target.value)}
                  />

                </Box>
                {selectedRows.length > 0 ?
                  <>
                    <Tooltip title={permissions && !permissions[0].delete ? "You don't have permisson to delete apps" : "Click to delete selected Apps"}>
                      <span>
                        <Button disabled={permissions && !permissions[0].delete} startIcon={<Delete />} onClick={handleClickOpen} variant='contained' sx={{ borderRadius: "24px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }} color='error'>Delete Selected Apps</Button>
                      </span>
                    </Tooltip>
                  </>

                  : <CreateNewApp margin={true} />}
              </Grid>

              <Paper variant='outlined' style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", borderRadius: "12px" }}>


                <Fade in={true}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(true ? { timeout: 500 } : {})}>
                  <div>
                    {rows && rows.length > 0 &&
                      <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "10px" }}>
                        {rows && <DataGrid
                          dense
                          checkboxSelection
                          disableSelectionOnClick
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = data.apps.nodes.filter((row) =>
                              selectedIDs.has(row.id),
                            );
                            setSelectedRows(selectedRows);
                          }}
                          rowHeight={70}
                          autoHeight={true}
                          density={"compact"}
                          scrollbarSize={1}
                          className={classes.root}
                          rows={rows ? rows : []}
                          disableColumnFilter={true}
                          columns={columnsApp}
                          loading={loading}
                          pageSize={10}
                          onCellClick={handleCellClick}
                          sx={{
                            border: "2px",
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                              width: '0.4em',
                              height: '0.4em',
                              borderRadius: "24px"
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                              background: '#f1f1f1',
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                              backgroundColor: '#cdcdcd',
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                              background: '#160044',
                            },
                          }}
                        />
                        }
                      </Box>
                    }
                  </div>
                </Fade>

              </Paper>
            </Box> :
            <Fade in={(rows.length === 0)} mountOnEnter unmountOnExit style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: 1000 } : {})}>
              <div style={{ margin: "10px" }}>
                <EmptyPageRenderCustom
                  mainTitle={<>Create an app {props.filterLocation && `in ${match.params.name}`} {props.filterOrg && `inside ${props.orgId.toString().toLowerCase()}`}</>}
                  primaryButtonText={"New App"}
                  primaryButtonAction={() => setopenCreateNewApp(true)}
                  secondaryTitle="Create, configure, deploy, and customize web apps effortlessly."
                  learnMoreLink="https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-Create"
                  iframeLink="https://konvey.s3.amazonaws.com/videos/video_62408755.mp4"
                  imageList={SupportedAppsList}
                  imageListTitle="Supported runtime"
                />
              </div>
            </Fade>}
        </div>
      }


      <Dialog
        open={open || delLoad}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {showProgress ? `Deleting...${deletedAppCount}/${selectedRows.length}` : `Delete ${selectedRows.length} Apps, are you sure?`}
        </DialogTitle>
        <DialogContent>
          {showProgress ?
            <LinearProgressWithLabel value={(100 * deletedAppCount) / selectedRows.length} /> : <DialogContentText id="alert-dialog-description">


              Deleting your App is irreversible and your all regions will be deleted too. please be certain.
            </DialogContentText>}
        </DialogContent>
        {!showProgress && <DialogActions>
          <Button fullWidth onClick={handleClose}>No, Close</Button>
          <Button disableElevation fullWidth variant='contained' color='error' onClick={handleDeleteSelectedApps} autoFocus>
            Yes, Delete Forever
          </Button>
        </DialogActions>}
      </Dialog>
    </div>

  );
}
