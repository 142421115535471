// comapnay name goes here
export const CONFIG_APP_BASE_COMPANY = 'nife';
export const CONFIG_APP_IS_PRODUCTION = true; // true means production

// Check if running on localhost (for local development purposes)
const isLocalhost = CONFIG_APP_BASE_COMPANY === "zeke" ? true : false; 

export const CONFIG_APP_METRICS_BASE_URL =  isLocalhost ? "http://10.20.153.123:30966" : "https://metrics.clb2.nifetency.com";
export const CONFIG_APP_CODE_SCAN_BASE_URL =  isLocalhost ? "http://10.20.153.122:5001" : "https://trufflehog.clb2.nifetency.com";
export const CONFIG_APP_DORA_METRICS_BASE_URL = "https://zwrykd2y4i.execute-api.eu-north-1.amazonaws.com";
export const CONFIG_APP_UPTIME_METRICS_BASE_URL =  isLocalhost ? "http://10.20.153.122:5002" : "https://getuptime.clb2.nifetency.com";
export const CONFIG_APP_UPTIME_METRICS_BACKEND_URL =  isLocalhost ? "http://10.20.153.122:8000" : "https://testuptimekuma.nifetency.com"; 

// Set primary colors based on company name
export const CONFIG_APP_PRIMARY_LIGHT_COLOR = CONFIG_APP_BASE_COMPANY === "nife" ? "#160044" : '#89553e';
export const CONFIG_APP_PRIMARY_DARK_COLOR = CONFIG_APP_BASE_COMPANY === "nife" ? "#e3f2fd" :  '#C59C9A';

// Set base API URL depending on environment: Production, Development, or Localhost
export const CONFIG_APP_BASE_API = isLocalhost
  ? 'http://10.20.153.122:80' // Localhost for local development
  : CONFIG_APP_IS_PRODUCTION
    ? 'https://api.nife.io' // Production API
    : 'https://devapi.nife.io'; // Development API

export const CONFIG_APP_SUPABASE_CLIENT = 'https://oswanyebdtidlhzomuds.supabase.co';
export const CONFIG_APP_SUPABASE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9zd2FueWViZHRpZGxoem9tdWRzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDU1MzExMTIsImV4cCI6MTk2MTEwNzExMn0.4HL4HKLc_7uje9p8Cl2ORUIZwRvWDO0Udp0jQSzTGzY';
export const CONFIG_APP_GOOGLE_LOGIN_CLIENT = "570713914591-fhpqlkq1bobjhp438ispf4bgdvbvtqg7.apps.googleusercontent.com";
