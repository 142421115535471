import { OpenInNew } from '@mui/icons-material';
import { Box, Button, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BsDatabase } from 'react-icons/bs';
import { FaCircle, FaDocker } from 'react-icons/fa';
import { RiCloseCircleFill, RiLineChartLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';  // Import useParams from react-router-dom
import Nav from '../Settings/Nav';
import MonolithAddService from './MonolithAddService';
import { CONFIG_APP_UPTIME_METRICS_BACKEND_URL, CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../configApp';
import ServiceDetails from './monolithServiceDetails';
import { AiFillInfoCircle, AiOutlineTable } from 'react-icons/ai';
import MetricsDisplay from './monolithActionMetricsDialog';

const MonolithOverview = () => {
    const { name } = useParams();  // Get the 'name' from the URL parameters using useParams()

    // State to store the fetched data
    const [overviewData, setOverviewData] = useState(null);
    const [showMetrics, setshowMetrics] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);  // State to control dialog visibility
    const [serviceToRemove, setServiceToRemove] = useState(null); // Service to remove
    const [monolithData, setMonolithData] = useState(null);

    const refetchMonolithData = async () => {
        try {
            const res = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/monolith/${name}`);
            const data = await res.json();
            setMonolithData(data);
        } catch (error) {
            console.error("Error fetching monolith data:", error);
        }
    };

    
    // Fetch data function to call the API
    const fetchData = async () => {
        try {
            const response = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/monolithservices/${name}`);
            const data = await response.json();
            setOverviewData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchMonoLithData = async () => {
        try {
            const res = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/monolith/${name}`);
            const data = await res.json();
            console.log(data)
            setMonolithData(data)
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        // Fetch data initially when component mounts
        fetchMonoLithData();
        fetchData();
    }, [name]);  // Re-run the effect when 'name' changes

    // If data is not loaded yet, show a loading state
    if (!overviewData) {
        return <div>Loading...</div>;
    }

    // Destructure the fetched data
    const { monolithName, services } = overviewData;


    // Function to handle deleting the service
    const handleDeleteService = async () => {
        try {
            const response = await fetch(`https://getuptime.clb2.nifetency.com/monolithservices/${serviceToRemove}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log(`Service ${serviceToRemove} deleted successfully.`);
                // Close the dialog and refresh the data
                setOpenDialog(false);
                setServiceToRemove(null);
                fetchData(); // Refetch data to reflect changes
            } else {
                console.error("Failed to delete service.");
            }
        } catch (error) {
            console.error("Error deleting service:", error);
        }
    };

    // Open the dialog to confirm removal
    const openDeleteDialog = (serviceName) => {
        setServiceToRemove(serviceName);  // Store the service name to be deleted
        setOpenDialog(true);  // Open the confirmation dialog
    };

    // Close the dialog without performing the delete
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setServiceToRemove(null); // Clear the service to remove
    };

    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <main style={{ flex: 1 }}>
                {/* Nav Component */}
                <Nav />

                {/* Main Content Container */}
                <Container maxWidth={false} style={{maxHeight:"100vh", height: '100%' }}>
                    <Paper
                        variant='outlined'
                        sx={{

                            border: 0,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row', // Align left and right sections in a row
                            bgcolor: "transparent",
                            borderRadius: '12px', // Rounded corners for the whole container
                        }}
                    >
                        <Grid container>

                            {/* Left Column (md=8) */}
                            <Grid item md={12}>
                                <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >

                                    <Grid
                                        container
                                        direction="row"
                                        sx={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >

                                        <Box>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                {true ? (
                                                    <svg className="checkmark" style={{ margin: "0px 8px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                    </svg>
                                                ) : (
                                                    <RiCloseCircleFill style={{ fontSize: "25px", margin: "0px 8px" }} />
                                                )}
                                                <Typography sx={{ my: 4, fontSize: "22px", textAlign: "center" }}>
                                                    Your Monolith is {true ? "Active" : "Inactive"}
                                                </Typography>
                                            </Grid>
                                        </Box>

                                        <MonolithAddService monolithName={monolithData?.name && monolithData.name} ipAddress={monolithData?.ipAddress} />
                                    </Grid>
                                    {/* <Typography variant="h5" gutterBottom>
                                        {monolithData?.name && monolithData.name}
                                    </Typography> */}

                                    {monolithData?.ipAddress && (
                                        <Typography variant="body2" component={"span"} gutterBottom>
                                            Running on <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.08 5.227A3 3 0 0 1 6.979 3H17.02a3 3 0 0 1 2.9 2.227l2.113 7.926A5.228 5.228 0 0 0 18.75 12H5.25a5.228 5.228 0 0 0-3.284 1.153L4.08 5.227Z"></path><path fill-rule="evenodd" d="M5.25 13.5a3.75 3.75 0 1 0 0 7.5h13.5a3.75 3.75 0 1 0 0-7.5H5.25Zm10.5 4.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm3.75-.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" clip-rule="evenodd"></path></svg> <strong>{monolithData.ipAddress}</strong>
                                        </Typography>
                                    )}

                                    {monolithData?.createdAt && (
                                        <Typography sx={{ mt: 1, color: "grey" }} variant="body2" component={"span"} gutterBottom>
                                            Created <strong>{new Date(monolithData.createdAt).toLocaleDateString()}</strong>
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Divider />
                            {services?.length ? <>

                                <Grid
                                    container
                                    direction="row"
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >

                                    <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2 }}>{showMetrics ? "Metrics" : "Services"} on {name} monolith</Typography>
                                    <Button startIcon={!showMetrics ? <RiLineChartLine/> : <AiOutlineTable/>} onClick={()=> setshowMetrics(!showMetrics)}>{showMetrics ? "Show Table" :"Show Metrics"}</Button>
                                </Grid>
                                {showMetrics ? <MetricsDisplay ipAddress={monolithData?.ipAddress} /> : <ServiceDetails ipAddress={monolithData?.ipAddress} refetchMonolithData={refetchMonolithData} openDeleteDialog={openDeleteDialog} service={services} />}
                            </> :

                                <Grid
                                    container
                                    direction="row"
                                    sx={{
                                        height: "200px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <AiFillInfoCircle />
                                    <Typography variant='body' sx={{ ml: 1, textAlign: "center" }}>You don't have any services, click on Create Service to add one.</Typography>
                                </Grid>
                            }


                        </Grid>
                    </Paper>
                </Container>
            </main>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove this service from the server?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteService}
                        color="error"
                        autoFocus
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default MonolithOverview;
