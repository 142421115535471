import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, CircularProgress, Snackbar } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import OneClickTrackifyDeploy from '../Components/MarketPlace/OneClickTrackifyDeploy';
import Nav from '../Components/Settings/Nav';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../configApp';

const appDetails = {
    name: 'Trackify',
    desc: 'Trackify is an advanced tracking app that helps you monitor activities across various platforms.',
    logo: 'https://example.com/trackify-logo.png',
    home: 'https://trackify.com',
    type: 'Tracking',
    iport: '8080',
    eport: '80',
    License: 'MIT',
    runtime: 'React',
};

const Trackify = () => {
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [image, setImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // Handle form input changes
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleUserIdChange = (e) => setUserId(e.target.value);

    // Handle the dropzone image upload
    const handleDrop = (acceptedFiles) => {
        setImage(acceptedFiles[0]);
        setError('');
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!email || !userId || !image) {
            setError('Please provide email, user ID, and an image.');
            return;
        }

        setUploading(true);
        setError('');

        const formData = new FormData();
        formData.append('email', email);
        formData.append('userId', userId);
        formData.append('image', image);

        try {
            const response = await axios.post(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/trackify-upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImageUrl(response.data.imageUrl);
            setUploading(false);
            setSnackbarOpen(true);  // Show success message
        } catch (err) {
            console.error('Error uploading image:', err);
            setError('Failed to upload image. Please try again.');
            setUploading(false);
        }
    };

    // Dropzone options
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*',
        maxFiles: 1,
        multiple: false,
    });

    return (
        <>
            <Nav />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                p={3}
            >
                <Typography variant="h4" gutterBottom>
                    Trackify by Nife
                </Typography>
                <Typography variant="body1" paragraph align="center">
                    Trackify is an advanced tracking app that helps you monitor activities across various platforms. Upload your image to start using the service.
                </Typography>

                <form onSubmit={handleUpload} style={{ width: '100%', maxWidth: '400px' }}>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="User ID"
                        value={userId}
                        onChange={handleUserIdChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    {/* Dropzone for image upload */}
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #1976d2',
                            borderRadius: 2,
                            padding: 3,
                            textAlign: 'center',
                            marginBottom: 2,
                            cursor: 'pointer',
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body2" color="textSecondary">
                            Drag & drop an image here, or click to select a file
                        </Typography>
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={uploading}
                    >
                        {uploading ? <CircularProgress size={24} color="inherit" /> : 'Upload Image'}
                    </Button>
                </form>

                {/* <OneClickTrackifyDeploy appDetails={appDetails} hideDeployButton={false} /> */}

                {error && (
                    <Typography color="error" variant="body2" align="center" mt={2}>
                        {error}
                    </Typography>
                )}

                {imageUrl && (
                    <Box mt={2} textAlign="center">
                        <Typography variant="h6">Image Uploaded Successfully!</Typography>
                        <img src={imageUrl} alt="Uploaded" width="200" />
                    </Box>
                )}

                {/* Snackbar for success message */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Image uploaded successfully!"
                />
            </Box>
        </>
    );
};

export default Trackify;
