import { AddCircleOutline, AddOutlined, Close } from '@mui/icons-material';
import { AppBar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { FaDocker, FaGithub, FaServer } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { RiSettingsFill } from 'react-icons/ri';
import { Transition } from '../Home/CreateNewApp/Main';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../configApp';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import MonolithGithubDeploymentOption from './monolithGithubDeployment';
import MonolithComplexDeployment from './MonolithComplexDeployment';

const MonolithAddService = ({ ipAddress, monolithName }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const [services, setServices] = useState([{ name: '', image: '', internalPort: '', externalPort: '', volumes: '', domain: '', envVariables: '', selectedDeploymentType: 'docker' }]);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar(); // Hook to trigger Snackbar

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setServices([{ name: '', image: '', internalPort: '', externalPort: '', volumes: '', domain: '', envVariables: '', selectedDeploymentType: 'docker' }]);
    };

    const handleServiceChange = (index, field, value) => {
        const updatedServices = [...services];
        updatedServices[index][field] = value;
        setServices(updatedServices);
    };

    const handleAddService = () => {
        const newService = { name: '', image: '', internalPort: '', externalPort: '', volumes: '', domain: '', envVariables: '', selectedDeploymentType: 'docker' };
        setServices([...services, newService]);
        setSelectedServiceIndex(services.length); // Select the newly added service
    };

    const handleRemoveService = (index) => {
        const updatedServices = services.filter((_, i) => i !== index);
        setServices(updatedServices);
        if (selectedServiceIndex === index) {
            setSelectedServiceIndex(0); // Reset to first service if the selected one was removed
        }
    };

    const onDrop = (acceptedFiles) => {
        const updatedServices = [...services];
        updatedServices[selectedServiceIndex].sourceCode = acceptedFiles[0]; // Assuming a single file upload
        setServices(updatedServices);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.zip,.tar,.tar.gz,.rar,.tar.xz,.tar.bz2,.tgz', // Accept common archive formats for source code
    });


    const handleSubmit = async () => {
        setIsSubmitting(true);

        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');

        // Prepare the services data
        const servicesData = services.map(service => ({
            name: service.name,
            image: service.image,
            internalport: service.internalPort,
            externalport: service.externalPort,
            domain: service.domain,
            volumes: service.volumes,
            environment: service.envVariables.trim().split('\n').map(line => line.trim()),
        }));

        const data = { services: servicesData, monolithName, ipAddress, status: "active" };

        try {
            const response = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/monolithservices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Add the token to the Authorization header
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                console.log(result.message);
                handleCloseDialog();
            } else {
                enqueueSnackbar(`${result?.error} - ${result?.message}` || 'Unknown error occurred', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Error submitting data: ' + error.message, { variant: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Function to validate if all required fields are filled in
    const isDeployButtonEnabled = () => {
        return services.every(service =>
            service.name &&
            service.image &&
            service.internalPort &&
            service.externalPort &&
            service.domain
        );
    };

    return (
        <div>
            <Button variant="contained" onClick={handleOpenDialog} startIcon={<AddOutlined />} sx={{ borderRadius: "24px", boxShadow: 'rgba(17, 12, 46, 0.05) 0px 30px 50px 5px' }}>
                Create Services
            </Button>

            <Dialog
                TransitionComponent={Transition}
                fullScreen={true}
                open={dialogOpen}
                onClose={handleCloseDialog}
            >
                <AppBar sx={{
                    position: 'relative',
                    boxShadow: "none",
                    background: theme.palette.action.hover,
                    color: theme.palette.text.secondary,
                    height: "80px",
                    paddingTop: "10px",
                }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                            size="large">
                            <Close />
                        </IconButton>

                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Create Multiple Services in Monolith
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Container>
                        {false && <MonolithComplexDeployment />}
                        <Grid container spacing={2}>
                            {/* Left Column: Service List */}
                            <Grid item md={4}>
                                <Box sx={{ mb: 2 }}>
                                    {services.map((service, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                marginBottom: 2,
                                                border: selectedServiceIndex === index ? `2px solid ${theme.palette.primary.main}` : 'none',
                                                cursor: 'pointer',
                                                '&:hover': { boxShadow: 2 }
                                            }}
                                            onClick={() => setSelectedServiceIndex(index)}
                                        >
                                            <CardContent sx={{ padding: 2 }}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    sx={{
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {isSubmitting ? <CircularProgress size={20} /> : <FaServer />}
                                                    <Typography variant="h6" sx={{ ml: 1 }}> {service.name || `Service ${index + 1}`}</Typography>
                                                </Grid>

                                                {isSubmitting ? <Typography variant="body2"> {"Creating Service.."}</Typography> :
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveService(index);
                                                        }}
                                                        color="error"
                                                        size="small"
                                                    >
                                                        Remove
                                                    </Button>}

                                            </CardContent>
                                        </Card>
                                    ))}
                                    <Button
                                        startIcon={<AddCircleOutline />}
                                        onClick={handleAddService}
                                        variant="text"
                                        fullWidth
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Add Service
                                    </Button>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                    
                                        onClick={handleSubmit}
                                        color="primary"
                                        endIcon={<IoIosArrowForward />}
                                        sx={{ mt: 2, height: 60, boxShadow: "rgba(58, 59, 69, 0.4) 0px 0.15rem 1.75rem 0px" }}
                                        disabled={!isDeployButtonEnabled() || isSubmitting}
                                    >
                                        Deploy Services
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Right Column: Service Form */}
                            <Grid item md={8}>
                                <Box sx={{ border: '1px solid #ccc', borderRadius: "12px", padding: 3 }}>
                                    <Typography sx={{ mb: 2 }} variant="h6"> Edit {services[selectedServiceIndex]?.name || `Service ${selectedServiceIndex + 1}`}</Typography>

                                    {/* Service Form for Editing */}
                                    {services[selectedServiceIndex] && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    size="small"
                                                    label="Service Name"
                                                    fullWidth
                                                    value={services[selectedServiceIndex].name}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'name', e.target.value)}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    size="small"
                                                    label="Domain"
                                                    fullWidth
                                                    value={services[selectedServiceIndex].domain}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'domain', e.target.value)}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={12}> Select deployment type: </Grid>
                                            <Grid item md={4}>
                                                <Button
                                                    disableElevation
                                                    onClick={() => handleServiceChange(selectedServiceIndex, 'selectedDeploymentType', 'docker')}
                                                    variant={services[selectedServiceIndex].selectedDeploymentType === 'docker' ? 'contained' : 'text'}
                                                    fullWidth
                                                    startIcon={<FaDocker />}
                                                >
                                                    Docker
                                                </Button>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Button
                                                    disableElevation
                                                    onClick={() => handleServiceChange(selectedServiceIndex, 'selectedDeploymentType', 'github')}
                                                    variant={services[selectedServiceIndex].selectedDeploymentType === 'github' ? 'contained' : 'text'}
                                                    fullWidth
                                                    startIcon={<FaGithub />}
                                                >
                                                    Github
                                                </Button>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Button
                                                    disableElevation
                                                    onClick={() => handleServiceChange(selectedServiceIndex, 'selectedDeploymentType', 'custom')}
                                                    variant={services[selectedServiceIndex].selectedDeploymentType === 'custom' ? 'contained' : 'text'}
                                                    fullWidth
                                                    startIcon={<RiSettingsFill />}
                                                >
                                                    Custom
                                                </Button>
                                            </Grid>

                                            {/* Deployment Type Specific Fields */}
                                            {services[selectedServiceIndex].selectedDeploymentType === 'docker' && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        size="small"
                                                        label="Docker Image"
                                                        fullWidth
                                                        value={services[selectedServiceIndex].image}
                                                        onChange={(e) => handleServiceChange(selectedServiceIndex, 'image', e.target.value)}
                                                        disabled={isSubmitting}
                                                        required
                                                    />
                                                </Grid>
                                            )}

                                            {services[selectedServiceIndex].selectedDeploymentType === 'github' && (
                                                <>
                                                    <Grid item xs={12}>

                                                        <MonolithGithubDeploymentOption serviceName={services[selectedServiceIndex].name} />
                                                    </Grid>
                                                </>

                                            )}

                                            {services[selectedServiceIndex].selectedDeploymentType === 'custom' && (
                                                <Grid item xs={12}>
                                                    <Box {...getRootProps()} sx={{
                                                        height: "80px",
                                                        border: '1px dashed #ccc', padding: 3, textAlign: 'center', cursor: 'pointer',
                                                    }}>
                                                        <input {...getInputProps()} />
                                                        <Typography>Drag and drop your source code here, or click to select files</Typography>
                                                    </Box>
                                                    {services[selectedServiceIndex].sourceCode && (
                                                        <Typography variant="body2" sx={{ mt: 2 }}>
                                                            {services[selectedServiceIndex].sourceCode.name} uploaded
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            )}

                                            {/* Internal and External Ports */}
                                            <Grid item xs={6}>
                                                <TextField
                                                    size="small"
                                                    label="Internal Port"
                                                    fullWidth
                                                    value={services[selectedServiceIndex].internalPort}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'internalPort', e.target.value)}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    size="small"
                                                    label="External Port"
                                                    fullWidth
                                                    value={services[selectedServiceIndex].externalPort}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'externalPort', e.target.value)}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </Grid>

                                            {/* Volumes */}
                                            <Grid item xs={12}>
                                                <TextField
                                                    size="small"
                                                    label="Volumes"
                                                    fullWidth
                                                    value={services[selectedServiceIndex].volumes}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'volumes', e.target.value)}
                                                    disabled={isSubmitting}
                                                />
                                            </Grid>

                                            {/* Environment Variables */}
                                            <Grid item xs={12}>
                                                <TextField
                                                    size="small"
                                                    label="Environment Variables (key=value format)"
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                    value={services[selectedServiceIndex].envVariables}
                                                    onChange={(e) => handleServiceChange(selectedServiceIndex, 'envVariables', e.target.value)}
                                                    disabled={isSubmitting}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MonolithAddService;
