import { gql, useQuery } from '@apollo/client';
import { Button, IconButton, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { BiLineChart } from 'react-icons/bi';
import { BsCheckCircleFill, BsFillBarChartLineFill } from 'react-icons/bs';
import { MdOutlineSpeed } from 'react-icons/md';
import { useRouteMatch } from "react-router-dom";
import LogTrigger from "../../../LogTrigger";
import DoraMetrics from '../../../Tools/DoraMetrics';
import SpeedInsightsLayout from '../../../Tools/SpeedInsightsLayout';
import UptimeStatus from '../../../Tools/UptimeStatus';
import { FETCH_USER_PAT } from '../../Home/CreateNewApp/StepTwo';
import { APP } from "../Overview/Main";
import { decodeAes } from '../Services/GithubTimeline';
import DataDog from "./DataDog";
import GraphsDisplay from './GraphanaGraphs';
import DNSmetricsLayout from '../../../Tools/DNSmetricsLayout';
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const USE_METRICS = gql`
query userMetricsMutation($appName: String!){
  userMetrics(appName : $appName) {
    resolverIp
    timeStamp
    queryType
  }
}`


const API_USAGE = gql`
query apiUsage($startTime: String!, $endTime: String!,$hostName: String!){
  getQueryLog(input: {startTime:$startTime,endTime:$endTime,hostName:$hostName}) {
    resolverIp
    times
  }
}`




export default function Main() {

  const [labels, setlabels] = React.useState([]);
  const [apps, setapps] = React.useState([]);
  const match = useRouteMatch();
  const [showMetrics, setshowMetrics] = React.useState("system")
  const [timeNow, settimeNow] = React.useState("0");
  const [startTime, setStartTime] = React.useState('2022-06-02 00:00:00');
  const [endTime, setEndTime] = React.useState('2022-06-09 17:25:37');
  const [hostName, setHostName] = React.useState(match.params.id);


  const [repoUrl, setrepoUrl] = useState("");
  const [token, settoken] = useState("");

  const { loading: load, error: err, data: appdata } = useQuery(APP, {
    variables: { name: match.params.id }, onCompleted: (data) => {
      if (data) {
        setrepoUrl((data.app.sourceUrl && data.app.sourceUrl.startsWith("https://github.com/")) ? data.app.sourceUrl.split("archive")[0] : "")
      }
    }
  });

  const { loading: loadlist, error: errlist, data: datalist } = useQuery(FETCH_USER_PAT, {
    onCompleted: (data) => {
      if (data && data.getUserPAT && data.getUserPAT[0]) {
        settoken(decodeAes(data.getUserPAT[0].patToken));
      }
    }
  });

  const { loading, error, data } = useQuery(API_USAGE, { variables: { startTime: startTime, endTime, hostName: `${hostName}.apps.nifetency.com` } });
  const selectedButtonStyle = (color) => ({
    bgcolor: color,
    boxShadow: `0 0 8px ${color}`,
    color: 'black',

    '&:hover': {
      boxShadow: `0 0 12px ${color}`,
      bgcolor: color,
    },
  });


  useEffect(() => {
    document.title = "Metrics"
    var time = Date.now()
    settimeNow(time)
    setlabels([])
    setStartTime(Moment().subtract(10, 'd').format('YYYY-MM-DD h:mm:ss'))
    setEndTime(Moment().format('YYYY-MM-DD h:mm:ss'))
    setapps([])
    if (data && data.getQueryLog && data.getQueryLog) {
      data.getQueryLog.map((i) => { setlabels(oldArray => [...oldArray, i.resolverIp]) })
      data.getQueryLog.map((i) => { setapps(oldArray => [...oldArray, Math.floor(i.times)]) })
    }


  }, [data]);



  // if (loading) {
  //   return (
  //     <div>
  //       <Skeleton style={{ margin: "10px" }} />
  //       <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
  //     </div>
  //   );
  // }
  if (error) return `Error! ${error.message}`;
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Metrics")
  }

  if (data) {
    LogTrigger("App metrics loaded", "info", window.location.href, "App Metrics")
  }

  if (appdata && appdata.app && appdata.app.status === "Suspended") {
    return (

      <Typography style={{ margin: "25px", fontWeight: "bold" }}>

        App is Suspended, Cannot show metrics.
      </Typography>
    )
  }

  return (
    <div style={{ margin: "25px" }}>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12} md={CONFIG_APP_BASE_COMPANY === "zeke" ? 6 : (repoUrl !== "" ? 2 : 3)}>
          <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
            App Metrics
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Button
            variant="outlined"
            fullWidth
            size='small'
            onClick={() => setshowMetrics("system")}
            sx={{
              borderRadius: '5px',
              textTransform: "capitalize",
              cursor: 'pointer',
              ...((showMetrics === "system") ? selectedButtonStyle('#00d7ff') : {}),
              border: 'solid 1px grey',
              '&:not(:hover)': {
                borderColor: 'grey',
              }
            }}
            startIcon={<BsFillBarChartLineFill style={{ fontSize: "20px" }} />}
          >
            <Typography sx={{ px: 1 }}>
              System Performance
            </Typography>
          </Button>
        </Grid>
        {CONFIG_APP_BASE_COMPANY === "nife" && <Grid item xs={6} md={2}>
          <Button
            variant="outlined"
            fullWidth
            size='small'
            disable={CONFIG_APP_BASE_COMPANY === "zeke" ? true : false}
            onClick={() => setshowMetrics("dns")}
            sx={{
              borderRadius: '5px',
              textTransform: "capitalize",
              cursor: 'pointer',
              ...((showMetrics === "dns") ? selectedButtonStyle('#02d1b4') : {}),
              border: 'solid 1px grey',
              '&:not(:hover)': {
                borderColor: 'grey',
              }
            }}
            startIcon={<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M21 12a9 9 0 1 0 -9 9"></path><path d="M3.6 9h16.8"></path><path d="M3.6 15h8.4"></path><path d="M11.578 3a17 17 0 0 0 0 18"></path><path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9"></path><path d="M18 21v-7m3 3l-3 -3l-3 3"></path></svg>}
          >
            <Typography sx={{ px: 1 }}>
              DNS
            </Typography>
          </Button>
        </Grid>}
        <Grid item xs={6} md={CONFIG_APP_BASE_COMPANY === "zeke" ? 3 : 2}>
          <Button
            variant="outlined"
            fullWidth
            size='small'
            onClick={() => setshowMetrics("uptime")}
            sx={{
              borderRadius: '5px',
              textTransform: "capitalize",
              cursor: 'pointer',
              ...((showMetrics === "uptime") ? selectedButtonStyle('#5cdd8b') : {}),
              border: 'solid 1px grey',
              '&:not(:hover)': {
                borderColor: 'grey',
              }
            }}
            startIcon={<BiLineChart style={{ fontSize: "20px" }} />}
          >
            <Typography sx={{ px: 1 }}>
              Uptime Status
            </Typography>
          </Button>
        </Grid>

        {repoUrl !== "" && (
          <Grid item xs={6} md={repoUrl !== "" ? 2 : 3}>
            <Button
              variant="outlined"
              fullWidth
              size='small'
              onClick={() => setshowMetrics("dora")}
              sx={{
                borderRadius: '5px',
                textTransform: "capitalize",
                cursor: 'pointer',
                ...((showMetrics === "dora") ? selectedButtonStyle('#fc8c00') : {}),
                border: 'solid 1px grey',
                '&:not(:hover)': {
                  borderColor: 'grey',
                }
              }}
              startIcon={(
                <svg stroke="currentColor" style={{ fontSize: "20px" }} fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-6.333 13a1 1 0 0 0 -1 1v2l.007 .117a1 1 0 0 0 1.993 -.117v-2l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 -4a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117a1 1 0 0 0 -.993 -.883zm3 2a1 1 0 0 0 -1 1v4l.007 .117a1 1 0 0 0 1.993 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"></path>
                </svg>
              )}
            >
              <Typography sx={{ px: 1 }}>
                DORA metrics
              </Typography>
            </Button>
          </Grid>
        )}

         {CONFIG_APP_BASE_COMPANY === "nife" && <Grid item xs={6} md={2}>
          <Button
            variant="outlined"
            fullWidth
            size='small'
            onClick={() => setshowMetrics("speed")}
            sx={{
              borderRadius: '5px',
              textTransform: "capitalize",
              cursor: 'pointer',
              ...((showMetrics === "speed") ? selectedButtonStyle('#DA70D6') : {}),
              border: 'solid 1px grey',
              '&:not(:hover)': {
                borderColor: 'grey',
              }
            }}
            startIcon={<MdOutlineSpeed style={{ fontSize: "20px" }} />}
          >
            <Typography sx={{ px: 1 }}>
              Speed Insights
            </Typography>
          </Button>
        </Grid>}
      </Grid>

      {showMetrics === "system" && <GraphsDisplay />}
      {showMetrics === "dns" && <DNSmetricsLayout appName={`${match.params.id}.clb2.nifetency.com`} />}
      {showMetrics === "uptime" && <UptimeStatus />}
      {showMetrics === "speed" && <SpeedInsightsLayout urltocheck={`https://${match.params.id}.clb2.nifetency.com`} />}
      {showMetrics === "dora" && <DoraMetrics githubrepo={repoUrl} token={token} />}
      {/* <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >

        {data.getQueryLog && <div>
          <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
            New Visitors
          </Typography>
          <Divider />
          <Grid container>
            <Grid item>
              <Typography style={{ margin: "10px 10px 10px 0px", color: "#FDBD47", fontSize: "initial", fontWeight: "bold" }}>
                {Math.min(...data.getQueryLog.map(item => item.times))} Min
              </Typography>
            </Grid>
            <Grid item>

              <Typography style={{ margin: "10px 10px 10px 0px", color: "red", fontSize: "initial", fontWeight: "bold" }}>
                {Math.max(...data.getQueryLog.map(item => item.times))} Max
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ margin: "10px 10px 10px 0px", color: "green", fontSize: "initial", fontWeight: "bold" }}>
                {(Math.max(...data.getQueryLog.map(item => item.times)) + Math.min(...data.getQueryLog.map(item => item.times))) / 2} Avg
              </Typography>
            </Grid>
          </Grid>

        </div>

        }


        {data.getQueryLog && <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >

          <MenuItem value={900000}>Last 15 minutes</MenuItem>
          <MenuItem value={3600000}>Last 1 Hour</MenuItem>

          <MenuItem value={86400000}>Last 24 Hours</MenuItem>
          <MenuItem value={7604800000}>Last 7 days</MenuItem>

        </Select>}
      </Grid>

      {data && data.getQueryLog &&
        <Bar options={options} data={graphdata} />
      } */}


    </div >
  );
}
