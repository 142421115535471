import { Button } from "@mui/material";
import { CONFIG_APP_BASE_COMPANY, CONFIG_APP_UPTIME_METRICS_BASE_URL } from "../../configApp";
import { OpenInNew } from "@mui/icons-material";
import axios from "axios";
import { useState, useEffect } from "react";

// Function to get the app port
function getAppPort(name) {
  return axios.get(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/get-app-port?name=${name}`)
    .then(response => response.data.port)  // Assuming response contains { port: number }
    .catch(error => {
      console.error("Error fetching the port:", error);
      return null;  // If there's an error, return null
    });
}

export default function AppOpenInLink({ data }) {
  const [port, setPort] = useState(null);

  useEffect(() => {
    if (CONFIG_APP_BASE_COMPANY === "zeke" && data?.row?.name) {
      getAppPort(data.row.name).then(fetchedPort => {
        setPort(fetchedPort);  // Store the port in the state
      });
    }
  }, [data]);

  // Check if the app is deployed or not
  const isDeployed = data.value !== "nife.io";
  
  return (
    <div style={{ textAlign: "left" }}>
      {/* If company is "zeke", show the app with port or fallback */}
      {CONFIG_APP_BASE_COMPANY === "zeke" ? (
        port ? (
          <Button
            endIcon={<OpenInNew />}
            size="small"
            onClick={() => window.open(`http://10.20.153.123:${port}`, '_blank')}  // Adjust the URL to use the port
          >
            Open App
          </Button>
        ) : (
          <span>Loading port...</span>  // Show loading text while waiting for the port
        )
      ) : (
        // Original behavior for other companies
        isDeployed ? (
          <Button
            endIcon={<OpenInNew />}
            disabled={data.row.imageName && (data.row.imageName.startsWith("mysql") || data.row.imageName.startsWith("postgres"))}
            size="small"
            onClick={() => window.open(data.value, '_blank')}
          >
            Open
          </Button>
        ) : (
          "Not Deployed"
        )
      )}
    </div>
  );
}
