import { Alert, AppBar, FormControlLabel, RadioGroup, Radio, Box, Button, Card, Chip, Collapse, Container, Dialog, Divider, Fade, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Toolbar, Tooltip, Typography, Zoom, Badge, ListItem } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react'
import { Transition } from '../CreateNewApp/Main';
import { Add, CheckCircle, Close, OpenInNew } from '@mui/icons-material';
import FrameworksList from "./frameworksnew.json"
import { FaCheckCircle, FaFolder, FaGithub, FaLock } from 'react-icons/fa';
import { MdAddCircle, MdError, MdOutlineCheck, MdOutlineCode, MdOutlineRemoveCircleOutline, MdUpload } from 'react-icons/md';
import { BsGearFill } from 'react-icons/bs';
import { ReactComponent as MySvgFile } from '../../../Images/deploy.svg';
import { IoIosArrowForward } from 'react-icons/io';
import UploadBuild from './UploadBuild';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ORG_LIST } from '../../Settings/Organization';
import { SITES_LIST } from './SitesTable';
import LoadingNife from '../../../Containers/LoadingNife';
import { AiOutlineArrowLeft, AiOutlineGithub } from 'react-icons/ai';
import { FiArrowUpLeft } from 'react-icons/fi';
import { BiWorld } from 'react-icons/bi';
import { CHECK_GITHUB_REPO_APP, FETCH_USER_PAT } from '../CreateNewApp/StepTwo';
import moment from 'moment';
import { Octokit } from '@octokit/rest';
import { decodeAes } from '../../Deployments/Services/GithubTimeline';


export const S3_DEPLOYMENT_MUTATION = gql`
  mutation S3DeploymentMutation(
    $s3AppName: String!,
    $s3Url: String,
    $deployBuildFile: Boolean,
    $buildFileName: String,
    $buildCommandsS3: [S3BuildCommands],
    $envVariablesS3: [S3EnvVariables],
    $organizationId: String 
  ) {
    S3Deployment(input: {
      s3AppName: $s3AppName, 
      deployBuildFile: $deployBuildFile,
      s3Url: $s3Url, 
      buildFileName: $buildFileName,
      buildCommandsS3: $buildCommandsS3,
      envVariablesS3: $envVariablesS3,
      organizationId: $organizationId
    }) 
  }
`;

export const REMOVE_FILES_S3 = gql`
mutation RemoveFilesMutation($s3AppName: String){
    removeFiles(s3AppName: $s3AppName)
  }
`


function AutoHideSpan({ title, name }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Set a timer to hide the span after 2 seconds
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 4000);

        // Clean up the timer if the component unmounts before the timer finishes
        return () => clearTimeout(timer);
    }, [isVisible]); // Empty dependency array means this effect runs only once after the initial render

    return (
        <>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"

                >
                    <p style={{ fontWeight: "bold", margin: "2px", marginLeft: "0px" }}>
                        {title}
                    </p>
                    {isVisible && <Zoom in={true}>
                        <Box sx={{ color: "#007fff", borderRadius: "25px", marginLeft: "5px" }}>
                            <Typography sx={{ fontSize: "13px" }}><FaCheckCircle /> Auto filled from {name} framework</Typography>
                        </Box>
                    </Zoom>}

                </Grid>
            </Box>


        </>
    );
}



export default function CreateNewSite({ hideButton, redeployOrg, openCreateNewSite, setopenCreateNewSite, redeploy, redeploySiteName }) {
    const [open, setopen] = useState(false);
    const [appName, setappName] = useState("fantastic-site-228709");
    const [outputFolder, setoutputFolder] = useState("");
    const [buildCommand, setbuildCommand] = useState("npm run build ");
    const [installCommand, setinstallCommand] = useState("yarn install");
    const [selectedFramework, setselectedFramework] = useState(null);
    const [deploymentType, setdeploymentType] = useState(false);
    const [buildFileUrl, setbuildFileUrl] = useState("");
    const [iserror, setIsError] = React.useState(false)
    const [nameValidation, setNameValidation] = React.useState(false)
    const [isAppDisable, setisAppDisable] = React.useState(false);
    const [variables, setVariables] = useState([{ name: '', value: '' }]);
    const [inputValue, setInputValue] = React.useState('');
    const [showError, setshowError] = useState(false);
    const [isQuickApps, setisQuickApps] = useState(false);

    const buildLoad = false;
    const revertApploading = false;
    const DPLoad = false;

    const { enqueueSnackbar } = useSnackbar();

    const [githubLink, setgithubLink] = useState("");
    const [listofrepo, setlistofrepo] = useState([]);
    const [showGithubFetchLoading, setshowGithubFetchLoading] = useState(false);
    const [githubBranch, setgithubBranch] = useState("main");
    const [gitUrlValidation, setGitUrlValidation] = React.useState(false)
    const valuevalidateGitUrl = (e) => {

        const checkvalue = e.target.value.substring(0, 19) === "https://github.com/"

        if (checkvalue.length > 0) {
            setGitUrlValidation(false)
        }
        setGitUrlValidation((checkvalue) ? false : true)

        if (e.target.value === "") {
            setGitUrlValidation(false)
        }
    }

    const [showGithubCheckError, setshowGithubCheckError] = useState(false);
    const [selectedfromgithublist, setselectedfromgithublist] = useState(false);

    const [checkGihubRepo, { data: gitrepo, loading: gitrepoLoad, error: gitrepoErr }] = useMutation(CHECK_GITHUB_REPO_APP, {

        onCompleted: () => {

            setshowGithubCheckError(false)
        }
        , onError: () => {
            setshowGithubCheckError(true)
        }
    });

    const { loading: loadorg, error: errorg, data: orgdata, refetch } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
        onCompleted: (data) => {

        }
    }
    );


    const { loading: loadlist } = useQuery(FETCH_USER_PAT, {
        onCompleted: (data) => {
            if ((data && data.getUserPAT && data.getUserPAT[0] && data.getUserPAT[0].patToken)) {
                const octokit = new Octokit({
                    auth: `token ${data && data.getUserPAT && decodeAes(data.getUserPAT[0].patToken)}`,
                });
                setshowGithubFetchLoading(true)
                async function requestUser() {
                    try {
                        const resp = await octokit.request('Get /user/repos', {});
                        setlistofrepo(resp.data.filter((e) => {
                            return !e.private
                        }))
                        setshowGithubFetchLoading(false)
                    }
                    catch (error) {
                        console.error('Error:', error);
                        setshowGithubFetchLoading(false)
                    }

                }


                requestUser();

            }
        }
    });
    const [removeFiles, { data: rfdata, loading: rfload, error: rferr }] = useMutation(REMOVE_FILES_S3, {
        onError: (error) => {
            enqueueSnackbar(error.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
        ,
        onCompleted: (data) => {
            console.log("file removed")
        }
    })
    const [deploySite, { data, loading, error }] = useMutation(S3_DEPLOYMENT_MUTATION, {
        refetchQueries: [SITES_LIST],
        onError: (error) => {
            setshowError(true)
            // removeFiles({ variables: { s3AppName: appName } })
        }
        ,
        onCompleted: (data) => {
            handleClose()
            // removeFiles({ variables: { s3AppName: appName } })
            enqueueSnackbar("Successfully deployed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });


    const handleDeploy = () => {
        setshowError(false)
        deploySite({
            variables: {
                deployBuildFile: false,
                buildCommandsS3: [
                    { s3Cmd: `${installCommand}` },
                    { s3Cmd: buildCommand }
                ],
                s3AppName: appName,
                s3Url: buildFileUrl,
                buildFileName: outputFolder,
                envVariablesS3: variables,
                organizationId: inputValue.id,
            },
        });
    };


    const handleDirectBuildFileDeploy = (fileUrl) => {
        setshowError(false)
        deploySite({
            variables: {
                deployBuildFile: true,
                buildCommandsS3: [
                ],
                s3AppName: appName,
                s3Url: fileUrl,
                buildFileName: "",
                envVariablesS3: variables,
                organizationId: inputValue.id,
            },
        });
    };

    const handleInputChange = (index, event) => {
        setshowError(false)
        const newVariables = [...variables];
        newVariables[index][event.target.name] = event.target.value;
        setVariables(newVariables);
    };

    const handleAddMore = () => {
        setVariables([...variables, { name: '', value: '' }]);
    };
    const handleDelete = (index) => {
        const newVariables = [...variables];
        newVariables.splice(index, 1);
        setVariables(newVariables);
    };


    const theme = useTheme()

    const handleClose = () => {
        if (openCreateNewSite) {
            setopenCreateNewSite(false)
        }
        setopen(false);
        setselectedfromgithublist(false)
    }

    const handleClickOpen = () => {


        setshowError(false);
        const superb = require('superb')
        if (redeploy) {
            if (orgdata && orgdata.organizations && orgdata.organizations.nodes) {
                orgdata.organizations.nodes.forEach(element => {
                    if ((element.type === "true" || element.type === "1") && element.id === redeployOrg.id) {
                        setInputValue(element)
                    }
                });
            }

            setappName(redeploySiteName)
        }
        else {
            if (orgdata && orgdata.organizations && orgdata.organizations.nodes) {
                orgdata.organizations.nodes.forEach(element => {
                    if (element.type === "true" || element.type === "1") {
                        setInputValue(element)
                    }
                });
            }
            setappName(superb.random() + "-site-" + Math.floor(1000 + Math.random() * 9000))
        }

        setopen(true);
        setselectedFramework(null)
        setbuildFileUrl(null)
        setgithubLink("")
    }
    const handleAppName = (e) => {
        setappName(e.target.value)
        setIsError(e.target.value)
    }

    const handleGithubInputUrl = useCallback((event) => {
        setshowGithubCheckError(false)
        setgithubLink(event.target.value)
        valuevalidateGitUrl(event);
        if (!selectedfromgithublist) {
            checkGihubRepo({ variables: { githubUrl: event.target.value } })
        }


    }, []);

    const valuevalidateName = (e) => {
        var regexp = /^([a-z0-9_](?:(?:[a-z0-9_-]|(?:(?!))){0,28}(?:[a-z0-9]))?)$/
        const checkvalue = regexp.test(e.target.value)
        if (checkvalue.length > 0) {
            setNameValidation(false)
        }
        setNameValidation((checkvalue) ? false : true);
        setisAppDisable(checkvalue ? false : true);
    }
    const handleSelectFW = (value) => {
        setshowError(false)
        if (value) {
            setselectedFramework(value)
            if (value.settings.outputDirectory.value) {
                setoutputFolder(value.settings.outputDirectory.value)
            }
            // if (value.settings.buildCommand.value) {
            //     setbuildCommand(value.settings.buildCommand.value)
            // }

        }


    }
    const [buildFileRadio, setbuildFileRadio] = useState("upload");

    const handleBuildFileChange = (event) => {
        setbuildFileRadio(event.target.value);
    }


    useEffect(() => {
        console.log("updated")
    }, [appName, orgdata]);


    useEffect(() => {
        if (openCreateNewSite) {
            handleClickOpen();
        }
        return () => {

        };
    }, [loadorg, open, openCreateNewSite]);



    return (
        <div>

            {!hideButton && <Button color="primary"
                sx={{
                    borderRadius: "24px",
                    boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px",
                    '&:hover': {
                        boxShadow: "rgba(147, 207, 255, 0.82) 0px 0.15rem 1.75rem 0px",
                        color: "#00d7ff",
                    },
                }}
                disableElevation variant='contained' startIcon={<Add />} onClick={handleClickOpen}>New Site</Button>}
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{
                    position: 'relative',
                    boxShadow: "none",
                    background: theme.palette.action.hover,
                    color: theme.palette.text.secondary,
                    height: "80px",
                    paddingTop: "10px",
                }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <Close />
                        </IconButton>

                        <TextField
                            variant='standard'
                            autoFocus
                            id="name"
                            label="Site name"
                            type="text"
                            disabled={loading || (redeploy ? true : false)}
                            required={true}
                            error={nameValidation && true}
                            helperText={iserror ? `Please enter Unique name, ${appName} Already Exits` : (nameValidation && "Please use lowercase letters and - (hyphen sign) only")}
                            style={{ marginBottom: "10px", marginLeft: "16px" }}
                            value={appName}
                            onChange={(e) => { handleAppName(e); valuevalidateName(e); setIsError(false); }}
                        />
                        {/* <Button onClick={() => setselectedFramework(null)}>go back</Button> */}
                    </Toolbar>
                </AppBar>
                <Collapse in={(loading) ? true : false} timeout="auto" unmountOnExit>
                    <Box sx={{ minHeight: "80vh", mt: 6 }}>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <LoadingNife title={"Site"} />
                        </Grid>
                    </Box>

                </Collapse>
                <Collapse in={(loading) ? false : true} timeout="auto" unmountOnExit>
                    {selectedFramework &&
                        <>
                            <LinearProgress variant="determinate" value={loading ? 100 : (buildFileUrl ? 66 : 33)} />
                            <Container >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ px: 3, mb: 2 }}
                                >
                                    <Typography sx={{ fontWeight: "bold", mt: 2 }} variant='h5'>{buildFileUrl ? "Almost there! Customize build configuration" : (deploymentType ? "Import from github" : `Upload your ${selectedFramework.name} project`)}</Typography>
                                    {buildFileUrl ? <Button disabled={loading} onClick={handleDeploy} endIcon={<IoIosArrowForward />} variant='contained' sx={{ mt: 2, boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>Deploy Site</Button>
                                        : <Box>
                                            {orgdata && orgdata.organizations && orgdata.organizations.nodes &&
                                                <Box sx={{ minWidth: 120, mt: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                                                        <Select
                                                            size='small'
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputValue}
                                                            disabled={redeploy ? (inputValue === "" ? false : true) : false}
                                                            label="Organizations"
                                                            onChange={(e) => setInputValue(e.target.value)}
                                                        >{orgdata.organizations.nodes.filter((e) => {
                                                            return e.parentId === ""
                                                        }).map((e) => {
                                                            return <MenuItem value={e}>{e.name}</MenuItem>
                                                        })}


                                                        </Select>
                                                    </FormControl>
                                                </Box>}



                                        </Box>}


                                </Grid></Container>
                        </>
                    }
                    <Divider />
                    <Container >

                        {error && showError &&
                            <Collapse in={true}>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center" sx={{ background: "rgb(253, 246, 246)", color: "rgb(211, 47, 47)", borderRadius: "12px", p: 2, mt: 2, mx: 2 }}>
                                    <MdError style={{ marginRight: "5px" }} />

                                    <b style={{ marginRight: "5px" }}>{error.message.includes("npm run build") ? "Build error " : "Error "}</b>
                                    {error.message}

                                </Grid>

                            </Collapse>}

                        {selectedFramework ?
                            <Box>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >

                                    {!buildFileUrl && <Box>

                                        <Box sx={{ my: 2 }}>
                                            <Button startIcon={<MdOutlineCode />} onClick={() => setdeploymentType(!deploymentType)} sx={{ mr: 2, px: 2 }} variant={deploymentType ? "text" : "contained"}>source Code deployment</Button>
                                            <Button startIcon={<FaGithub />} onClick={() => setdeploymentType(!deploymentType)} sx={{ px: 2 }} variant={!deploymentType ? "text" : "contained"}>Github deployment</Button>
                                        </Box>
                                    </Box>}


                                    <Box sx={{ my: 2 }}>
                                        {deploymentType ?
                                            <>
                                                {inputValue ? <GithubOption /> : "please select org"}
                                            </>

                                            :
                                            <Box>
                                                {buildFileUrl ?

                                                    <Box sx={{ mt: 2, ml: 3 }}>

                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >

                                                            <Grid item xs={12} md={9}>
                                                                <p style={{ fontWeight: "bold", margin: "2px", marginLeft: "0px" }}>Environment variables</p>
                                                            </Grid>
                                                            <Grid item xs={12} md={3}>
                                                                <Button size='small' startIcon={<MdAddCircle />} fullWidth onClick={handleAddMore}>Add More variable</Button>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                                                                    Provide the Environment Variables for Build and Runtime.
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <div>
                                                                    {variables.map((variable, index) => (
                                                                        <Grid key={variable.name} container spacing={2}>
                                                                            <Grid item xs={5}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    size='small'
                                                                                    sx={{ pb: 2 }}
                                                                                    name="name"
                                                                                    label="Name"
                                                                                    variant="outlined"
                                                                                    value={variable.name}
                                                                                    onChange={(event) => handleInputChange(index, event)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    size='small'
                                                                                    sx={{ pb: 2 }}
                                                                                    name="value"
                                                                                    label="Value"
                                                                                    variant="outlined"
                                                                                    value={variable.value}
                                                                                    onChange={(event) => handleInputChange(index, event)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <IconButton onClick={() => handleDelete(index)}>
                                                                                    <MdOutlineRemoveCircleOutline />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}

                                                                </div>
                                                            </Grid>



                                                            <Grid item xs={12} md={12}>


                                                                <AutoHideSpan name={selectedFramework.name} title={"Select Install Command"} />

                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                                                                    The command that is used to install your Project's software dependencies.
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Box>
                                                                    {selectedFramework.settings.installCommand.placeholder.map((e) => {
                                                                        return <Chip
                                                                            key={e}
                                                                            sx={{ mr: 1 }} color={installCommand === e ? "primary" : "default"} onClick={() => { setinstallCommand(e); setshowError(false); }} clickable label={e} />
                                                                    })}
                                                                </Box>
                                                            </Grid>



                                                            <Grid item xs={12} md={12}>

                                                                <AutoHideSpan name={selectedFramework.name} title={"Build command"} />

                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                                                                    The command your frontend framework provides for compiling your code.
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <TextField
                                                                    onChange={(e) => { setbuildCommand(e.target.value); setshowError(false); }}
                                                                    size='small' placeholder='Output directory' value={buildCommand}
                                                                    id="outlined-start-adornment"
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">
                                                                            <BsGearFill />
                                                                        </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={12}>

                                                                <AutoHideSpan name={selectedFramework.name} title={"Output directory"} />

                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                                                                    The directory in which your compiled frontend will be located.
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <TextField
                                                                    onChange={(e) => { setoutputFolder(e.target.value); setshowError(false); }}
                                                                    size='small' placeholder='Output directory' value={outputFolder}
                                                                    id="outlined-start-adornment"
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">
                                                                            <FaFolder />
                                                                        </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>






                                                        </Grid>
                                                    </Box>

                                                    :
                                                    <>

                                                        {inputValue ? <Box>
                                                            <Alert severity="info">
                                                                <span style={{ fontSize: "12px" }}>
                                                                    <b>Pro tip:</b> Do not include <i>node_modules</i> folder in your archive file to reduce file size.
                                                                </span></Alert>
                                                            <Box sx={{ p: 0.2, pb: 1 }}>
                                                                <UploadBuild setbuildFileUrl={setbuildFileUrl} maxSizeInBytes={104857600} appName={appName} />

                                                            </Box>

                                                        </Box> : "Please select organization to upload file"}
                                                    </>

                                                }
                                            </Box>
                                        }
                                    </Box>

                                </Grid>
                            </Box> :
                            <Box sx={{ my: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5.75}>
                                        <Paper variant='outlined' sx={{ p: 5, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px" }}>
                                            <Typography variant='h4'>{redeploy ? "Redeploy" : "Deploy"} your build</Typography>
                                            <Typography sx={{ my: 1 }}>
                                                Simply create your build locally, upload it, and watch your website go live.
                                            </Typography>

                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={buildFileRadio} onChange={handleBuildFileChange}
                                            >
                                                <FormControlLabel value="upload" control={<Radio />} label="Upload build file" />
                                                <FormControlLabel value="import" control={<Radio />} label="Import from github" />

                                            </RadioGroup>
                                            {buildFileRadio === "import" ? <GithubOption directBuild={true} /> :
                                                <UploadBuild handleDirectBuildFileDeploy={handleDirectBuildFileDeploy} directBuildFile={true} setbuildFileUrl={setbuildFileUrl} maxSizeInBytes={104857600} appName={appName} />}
                                            <Box>
                                                {orgdata && orgdata.organizations && orgdata.organizations.nodes &&
                                                    <Box sx={{ minWidth: 120, my: 2 }}>
                                                        <FormControl fullWidth>

                                                            <Select
                                                                size='small'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputValue}
                                                                disabled={redeploy ? true : false}
                                                                placeholder="Organizations"
                                                                sx={{ borderRadius: "12px" }}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                            >{orgdata.organizations.nodes.filter((e) => {
                                                                return e.parentId === ""
                                                            }).map((e) => {
                                                                return <MenuItem sx={{ textTransform: "capitalize" }} value={e}>{e.name.toLowerCase()} organization</MenuItem>
                                                            })}


                                                            </Select>
                                                        </FormControl>
                                                    </Box>}

                                            </Box>
                                            <Typography variant='body2' sx={{ my: 1 }}>
                                                Effortlessly deploy your website by uploading a build file generated with <strong>npm run build</strong>.</Typography>

                                            <Grid
                                                container

                                            >
                                                <Grid item xs={6}>
                                                    <Typography variant='body2' sx={{ my: 1 }}>
                                                        Start showcasing your online presence with ease today!
                                                    </Typography>
                                                    <a href="https://docs.nife.io/docs/UI%20Guide/Site%20deployment/Build-File-Deployment" target="_blank" rel="noopener noreferrer"> <Button size='small' endIcon={<IoIosArrowForward />}> Learn more </Button> </a>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <MySvgFile style={{ height: "100%", maxWidth: "600px" }} />
                                                </Grid>


                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={0.5} >
                                        <Box>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ minHeight: "100vh" }}
                                            >
                                                <Typography sx={{ color: "grey" }} variant='h6'>OR</Typography>
                                            </Grid>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} md={5.75}>
                                        <Paper variant='outlined' sx={{ p: 5, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px" }}>
                                            <Typography variant='h4'>Create a build and {redeploy ? "redeploy" : "deploy"}</Typography>
                                            <Typography sx={{ my: 1 }}>Use our platform to Create an optimized production build and deploy site on the fly.</Typography>
                                            <Paper
                                                variant='outlined'
                                                sx={{
                                                    my: 3,
                                                    p: 2, border: "2px solid #160044",
                                                    background: "#f5f5f5a6",
                                                    color: theme.palette.text.secondary,
                                                }}>
                                                <Typography sx={{ mt: 2, fontWeight: "bold" }} variant='h5'>Let's start choosing a framework..</Typography>
                                                <Box sx={{ my: 2 }}>
                                                    <Grid container spacing={2}>
                                                        {FrameworksList && FrameworksList.map((e, i) => {
                                                            return <Grid item xs={4} key={e.name}>
                                                                <Fade in={true}>
                                                                    <Card
                                                                        onClick={() => handleSelectFW(e)}
                                                                        sx={{
                                                                            p: 2,
                                                                            fontWeight: "normal",
                                                                            transition: 'background-color 0.4s',
                                                                            '&:hover': {
                                                                                background: theme.palette.action.hover,
                                                                                color: theme.palette.text.secondary,
                                                                                fontWeight: "bold",
                                                                            },
                                                                        }}
                                                                        variant='outlined'>
                                                                        <Box sx={{
                                                                            zIndex: 20,
                                                                            transition: 'transform 0.3s',
                                                                            '&:hover': {
                                                                                transform: 'scale(0.9)',
                                                                            },
                                                                        }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                direction="column"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <img src={e.logo} width={"auto"} height={30} />
                                                                                <Box sx={{ mt: 1 }}>
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justifyContent="center"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Typography sx={{ fontSize: "12px", fontWeight: "normal" }}>{e.name}</Typography>
                                                                                        {false && <IconButton><OpenInNew /></IconButton>}
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Card>
                                                                </Fade>
                                                            </Grid>
                                                        })}
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                            <Typography variant='body2' sx={{ my: 1 }}>
                                                Start showcasing your online presence with ease today!  </Typography>
                                            <a href="https://docs.nife.io/docs/UI%20Guide/Site%20deployment/Create-build" target="_blank" rel="noopener noreferrer"><Button size='small' endIcon={<IoIosArrowForward />}>Learn how it works</Button>
                                            </a>
                                        </Paper>
                                    </Grid>
                                </Grid>

                            </Box>}
                    </Container>
                </Collapse>
            </Dialog>
        </div>
    )

    function BranchesList({ githubUrl }) {
        const [branches, setBranches] = useState([]);
        const octokit = new Octokit(); // You can pass an auth token if needed

        const [owner, repo] = parseGitHubUrl(githubUrl);

        useEffect(() => {
            const fetchBranches = async () => {
                if (owner && repo) {
                    try {
                        const response = await octokit.rest.repos.listBranches({
                            owner,
                            repo,
                        });
                        setBranches(response.data);
                    } catch (error) {
                        console.error('Error fetching branches:', error);
                    }
                }
            };

            fetchBranches();
        }, [owner, repo]);




        return (
            <div>
                <Grid container spacing={1}>
                    {branches.length && <Grid item>
                        <Typography>Select a branch: </Typography>
                    </Grid>}
                    {branches.map(branch => (
                        <Grid item>
                            <Chip
                                key={branch.name}
                                variant="filled"
                                color={githubBranch === branch.name ? "primary" : "default"}
                                label={branch.name}
                                onClick={() => setgithubBranch(branch.name)}
                            />
                        </Grid>
                    ))}
                </Grid>

            </div>
        );
    }
    // Helper function to parse GitHub URL
    function parseGitHubUrl(url) {
        const regex = /github\.com\/([^\/]+)\/([^\/]+)/;
        const match = url.match(regex);
        return match ? [match[1], match[2]] : [null, null];
    }

    function GithubOption({ directBuild }) {
        return (
            <Box sx={{ mt: 1, minWidth: "400px" }}>


                {selectedfromgithublist ? <ListItem
                    secondaryAction={
                        <IconButton onClick={() => {
                            setgithubLink("");
                            setselectedfromgithublist(false);
                        }}>
                            <Close />
                        </IconButton>


                    }>
                    <ListItemIcon>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <CheckCircle style={{ color: "green", fontSize: "15px" }} />
                            }
                        >
                            <AiOutlineGithub style={{ fontSize: "30px" }} />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={
                        githubLink.split("https://github.com/")[1]
                    }
                        secondary={<>{githubBranch} branch</>}

                    />
                </ListItem> :
                    <TextField value={githubLink}
                        onChange={handleGithubInputUrl}
                        helperText={gitUrlValidation && "Not Github URL, It should start with 'https://github.com/...' "}
                        error={gitUrlValidation}
                        disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : (selectedfromgithublist ? true : false))} style={{ marginBottom: "10px" }} fullWidth variant='outlined' placeholder='ex. https://github.com/prose/gatekeeper.git' label={"Github link"} />}

                {githubLink && <Box sx={{ borderRadius: "12px", background: "#f5f5f5" }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ marginBottom: "10px" }}
                    >
                        {gitrepoLoad && <Typography color="primary" sx={{ p: "10px", color: "#111", minWidth: "400px" }}>Checking...</Typography>}

                        {gitrepoErr && showGithubCheckError && !selectedfromgithublist && !gitUrlValidation && <Typography color="error" style={{ fontSize: "13px", padding: "10px" }}> {gitrepoErr && gitrepoErr.message}, <b>If your repo is private we cannot access as per now.</b></Typography>}
                    </Grid>
                </Box>}

                {!selectedfromgithublist && githubLink && <BranchesList githubUrl={githubLink} />}

                <Fade in={showGithubFetchLoading}>
                    <LinearProgress />
                </Fade>

                {githubLink !== "" && !buildLoad && selectedfromgithublist && listofrepo && listofrepo.length > 0 && <Button sx={{ mt: 1 }} onClick={() => { setselectedfromgithublist(false); setgithubLink(""); setgithubBranch("main") }} startIcon={<AiOutlineArrowLeft />} fullWidth size='small'>Back to list of repositories</Button>}

                {githubLink === "" && <Box>
                    <Typography sx={{ mt: 1 }}>{listofrepo.length} public repositories</Typography>
                    <List dense={true}>
                        {listofrepo && listofrepo.sort((a, b) => {
                            return new Date(b.updated_at) - new Date(a.updated_at);
                        }).map((e) => {
                            var lastUpdated = moment(new Date(e.updated_at), "YYYY MM DD hh:mm:ss").fromNow()
                            return (<Tooltip key={e.id} arrow title={e.description}>
                                <ListItemButton key={e.id} onClick={() => {
                                    setgithubBranch(e.default_branch);
                                    setgithubLink(e.html_url);
                                    setshowGithubCheckError(false)
                                    setselectedfromgithublist(true)

                                }} secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        <FiArrowUpLeft />
                                    </IconButton>
                                }>
                                    <ListItemIcon>
                                        <AiOutlineGithub size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        e.full_name
                                    }
                                        secondary={<>Last updated {lastUpdated} • {e.private ? "Private" : "Public"}{e.private ? <FaLock size={10} style={{ marginLeft: "5px" }} /> : <BiWorld size={10} style={{ marginLeft: "5px" }} />} </>} />
                                </ListItemButton>
                            </Tooltip>)
                        })}
                    </List>
                </Box>}

                {githubLink !== "" && <Button sx={{ my: 1 }} disabled={gitrepoErr && showGithubCheckError} fullWidth variant='contained' disableElevation onClick={() => {
                    if (githubLink.endsWith(".git")) {
                        setbuildFileUrl(`${githubLink.substring(0, githubLink.length - 4)}/archive/refs/heads/${githubBranch}.zip`)
                    }
                    else {
                        setbuildFileUrl(`${githubLink}/archive/refs/heads/${githubBranch}.zip`)
                    }
                    if (directBuild) {
                        handleDirectBuildFileDeploy(githubLink.endsWith(".git") ? `${githubLink.substring(0, githubLink.length - 4)}/archive/refs/heads/${githubBranch}.zip` : `${githubLink}/archive/refs/heads/${githubBranch}.zip`)
                    }

                    ; setdeploymentType(false);
                }}> Done</Button>}

            </Box>
        )
    }
}
