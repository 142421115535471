import { useQuery } from '@apollo/client';
import { Avatar, CssBaseline, Drawer, Fade, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { BiBuildings } from "react-icons/bi";
import { USER_INFO } from '../Components/Home/Profile';
import { CustomTooltip } from '../Components/Settings/Organization';
import { NewListItems } from './listItems';

export default function SideBar(props) {
  const [open, setOpen] = React.useState(true); // Persistent Drawer state
  const [hover, setHover] = React.useState(false); // State to track hover
  const { data: userdata, refetch } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);


  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />



      {/* Drawer */}
      {useMediaQuery('(min-width:600px)') && (<Drawer
        variant="permanent"
        open={open}
        sx={{
          background: "transparent",
          position: 'relative',
          maxHeight: '99vh',
          overflowY: 'auto',
          whiteSpace: 'nowrap',
          width: hover ? '230px' : '90px',  // Change width based on hover state
          scrollbarWidth: 'none',
          transition: (theme) => theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: 0,
          }),
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            width: '100%',
          },
        }}
        onMouseEnter={() => setHover(true)}   // Set hover state to true when mouse enters
        onMouseLeave={() => setHover(false)}  // Set hover state to false when mouse leaves
      >
        <Grid container direction="column" justifyContent="center" alignItems="flex-start">
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '0 8px',
          }}>
            <Fade in={true} style={{ transformOrigin: '0 0 0' }}>
              <div>
                {userdata && (
                  <CustomTooltip title={userdata.getUserById.companyName} placement="right-start">

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Avatar
                          sx={{
                            borderRadius: "12px",
                            height: "45px",
                            width: "45px",
                            margin: "15px",
                            border: "0px solid #160044",
                          }}
                          src={userdata.getUserById.companylogo}
                        >
                          <BiBuildings style={{ fontSize: "20px", marginTop: "20px", marginLeft: "20px", textAlign: "center" }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={8}>
                        {hover && <Typography>{userdata.getUserById.companyName}</Typography>}

                      </Grid>
                    </Grid>
                  </CustomTooltip>
                )}
              </div>
            </Fade>
          </div>
          <NewListItems hover={hover} />
        </Grid>
      </Drawer>)}

      {/* Content (Children) */}
      <Grid
        container
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
}
