import { Check, Close, OpenInNew } from '@mui/icons-material';
import { Alert, AlertTitle, AppBar, Avatar, Box, Chip, CircularProgress, Container, Fab, Grid, IconButton, TextField, Toolbar, Typography, Zoom } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomTooltip } from '../Settings/Organization';
import { AiFillThunderbolt } from 'react-icons/ai';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../configApp';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const appDetails = {
    name: 'Trackify',
    desc: 'Trackify is an advanced tracking app that helps you monitor activities across various platforms.',
    logo: 'https://example.com/trackify-logo.png',
    home: 'https://trackify.com',
    type: 'Tracking',
    iport: '8080',
    eport: '80',
    License: 'MIT',
    runtime: 'React',
};

export default function OneClickTrackifyDeploy({ hideDeployButton }) {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    const [startLoading, setStartLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [appName, setAppName] = React.useState('');
    const [defaultOrg, setDefaultOrg] = React.useState('');

    let history = useHistory();










 

    const handleClickOpen = () => {
        setOpen(true);

        handleEnableCreateApp();

    };

    const handleClose = () => {
        setOpen(false);
        if (hideDeployButton) {
            history.push('/');
        }
    };

    const handleEnableCreateApp = async () => {
        setStartLoading(true);
        let randomAppName = formatString(appDetails.name) + '-by-nife-' + Math.floor(100000 + Math.random() * 900000);
        // setAppName(randomAppName);

        try {
            // API call to deploy the site
            const response = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/deploy-site-marketplace`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: randomAppName,
                    organization_id: 'd39d5cd5-6032-430b-8b25-128d631a9275', // Make sure to replace this with dynamic org id
                    createdBy: localStorage.getItem("userId"), // Use dynamic user id if required
                    deployment_time: '884.487732ms',
                    build_time: '884.487732ms',
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to deploy site');
            }

            // Assuming the response contains success status or data
            const data = await response.json();
            console.log('Deployment Successful:', data);

            enqueueSnackbar('Site deployed successfully!', { variant: 'success' });

            setStartLoading(false);
            history.push(`/sites/${randomAppName}`);
            handleClose()

        } catch (error) {
            console.error('Error deploying site:', error);
            enqueueSnackbar('Deployment failed. Please try again.', { variant: 'error' });
            setStartLoading(false);
        }
    };



    const formatString = (inputString) => {
        const formattedString = inputString.replace(/\s+/g, '-').toLowerCase();
        const alphanumericString = formattedString.replace(/[^a-z0-9-]/g, '');
        return alphanumericString;
    };

    React.useEffect(() => {
        // Fetch organization data, if needed, and set defaultOrg
    }, [defaultOrg]);

    return (
        <React.Fragment>
            {!hideDeployButton && (
                <Button
                    startIcon={<RiAddLine />}
                    variant="contained"
                    sx={{
                        boxShadow: 'rgba(17, 12, 46, 0.04) 0px 20px 30px 0px',
                        borderRadius: '24px',
                    }}
                    onClick={handleClickOpen}
                >
                    Deploy
                </Button>
            )}

            <Dialog
                open={open}
                fullScreen
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <AppBar
                    sx={{
                        position: 'relative',
                        boxShadow: 'none',
                        background: theme.palette.action.hover,
                        color: theme.palette.text.secondary,
                        height: '80px',
                        paddingTop: '10px',
                    }}
                >
                    <Toolbar>
                        <IconButton
                            disabled={startLoading}
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ flex: 1 }}>Trackify deployment</Typography>
                    </Toolbar>
                </AppBar>
                <Container sx={{ mt: 10 }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <h3>{appDetails.name}</h3>
                                <div>
                                    {appDetails.home && (
                                        <CustomTooltip arrow title="Visit homepage">
                                            <IconButton color="info" onClick={() => window.open(appDetails.home, '_blank')}>
                                                <OpenInNew />
                                            </IconButton>
                                        </CustomTooltip>
                                    )}
                                </div>
                            </Grid>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {appDetails.desc}
                            </Typography>
                            <Box
                                sx={{
                                    background: '#160044',
                                    color: '#fff',
                                    p: 2,
                                    borderRadius: '12px',
                                    boxShadow: 'rgba(17, 12, 46, 0.05) 0px 30px 50px 05px',
                                }}
                            >
                                <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={1} sx={{ p: 2 }}>
                                    <Grid item>
                                        <Chip sx={{ mb: 1 }} variant="filled" color="info" size="small" label={appDetails.type} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Image: {appDetails.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            Ports: Internal Port: {appDetails.iport} & External Port: {appDetails.eport}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {appDetails.env ? (
                                                <span>
                                                    This App requires {appDetails.env && appDetails.env.length} variables
                                                    <br />
                                                    {appDetails.env && appDetails.env.map((i) => `${i.key}, `)}
                                                </span>
                                            ) : (
                                                <span>No Environment Variables Needed</span>
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">License: {appDetails.License}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Runtime: {appDetails.runtime}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ mb: 8, mt: 2 }}
                            >

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ m: 1, position: 'relative' }}>
                                        <Avatar style={{ width: "56px", height: "56px" }} src={appDetails.logo} >
                                            <Fab
                                                aria-label="save"
                                                color="primary"
                                                sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px" }}
                                            >
                                                <AiFillThunderbolt style={{ fontSize: "23px" }} />
                                            </Fab>
                                        </Avatar>


                                        {startLoading && <CircularProgress
                                            size={68}
                                            sx={{
                                                color: "#00d7ff",
                                                position: 'absolute',
                                                top: -6,
                                                left: -6,
                                                zIndex: 1,
                                            }}
                                        />}

                                    </Box>
                                </Box>
                                <Typography sx={{ ml: 1 }} variant='h5'>
                                    Deploying {appDetails.name}...

                                </Typography>
                            </Grid>
                            {/* Ensure status is displayed */}
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>


                                    <Zoom in={true}>
                                        <Alert severity="info" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                            <AlertTitle>Deploying</AlertTitle>
                                            please wait... deploying app with default region(s)
                                        </Alert>
                                    </Zoom> </Grid>
                                <Grid item>
                                    <Zoom in={true}>
                                        <Alert icon={<Check fontSize="inherit" />} severity="success" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                            <AlertTitle>Success</AlertTitle>
                                            Configured the site with <strong>{appDetails.name}'s</strong> details.
                                        </Alert>
                                    </Zoom>
                                </Grid>
                                <Grid item>
                                    <Zoom in={true}>
                                        <Alert icon={<Check fontSize="inherit" />} severity="success" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                            <AlertTitle>Success</AlertTitle>
                                            Created a site <strong>{appName}</strong>.
                                        </Alert>
                                    </Zoom>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </React.Fragment>
    );
}
