import React, { useMemo, useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

import DialogContent from '@mui/material/DialogContent';
import { gql, useMutation, useQuery } from '@apollo/client';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import axios from "axios";
import { SiDocker } from "react-icons/si";

import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Route, Redirect, BrowserRouter, Switch, useRouteMatch, useLocation } from "react-router-dom"
import { APP_LOCATION_LIST } from "../Deploy/Main"
import { APP } from "../Overview/Main"
import { CONFIG_APP_BASE_API } from '../../../configApp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  hrcontainer: {
    display: "flex",
    alignItems: "center",
  },
  hrborder: {
    borderBottom: "1px solid grey",
    width: "50%",
  },
  hrcontent: {
    padding: "0 10px 0 10px",
  },
  input: {
    "&:invalid": {
      border: "red solid 2px"
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const definition = {
  "definition": {
    "kill_signal": "SIGINT",
    "kill_timeout": 5,
    "env": {},
    "experimental": {
      "allowed_public_ports": [],
      "auto_rollback": true
    },
    "services": [
      {
        "http_checks": [],
        "internal_port": 3000,
        "protocol": "tcp",
        "script_checks": [],
        "ports": [
          {
            "handlers": [
              "http"
            ],
            "port": 80
          },
          {
            "handlers": [
              "tls",
              "http"
            ],
            "port": 443
          }
        ],
        "tcp_checks": [
          {
            "grace_period": "1s",
            "interval": "15s",
            "restart_limit": 6,
            "timeout": "2s"
          }
        ],
        "concurrency": {
          "hard_limit": 25,
          "soft_limit": 20,
          "type": "connections"
        }
      }
    ]
  },
  "valid": true,
  "errors": [
    "no error"
  ]
}


const jsondata = {
  "env": {

  },
  "experimental": {
    "allowed_public_ports": [

    ],
    "auto_rollback": true
  },
  "kill_signal": "SIGINT",
  "kill_timeout": 5,
  "services": [
    {
      "concurrency": {
        "hard_limit": 25,
        "soft_limit": 20,
        "type": "connections"
      },
      "http_checks": [

      ],
      "internal_port": 8080,
      "ports": [
        {
          "handlers": [
            "http"
          ],
          "port": 80
        },
        {
          "handlers": [
            "tls",
            "http"
          ],
          "port": 443
        }
      ],
      "protocol": "tcp",
      "script_checks": [

      ],
      "tcp_checks": [
        {
          "grace_period": "1s",
          "interval": "15s",
          "restart_limit": 6,
          "timeout": "2s"
        }
      ]
    }
  ]
}



const UPDATE_APP = gql`
mutation($appId: String!,$internalPort: String!,$externalPort: String!, $build: String!){
  updateApp(input: {appId:$appId,build: $build, internalPort:$internalPort, externalPort:$externalPort}){
    id,
    name,
    status,
    deployed,
    hostname,
    appUrl,
    version,
    imageName,
    port,
    config{
      definition
    }
  }
}
`
const DEPLOY_IMAGE = gql`
  mutation deployImageMutation( 
  $appId: String!,
  $image: String! ,
  $definition: Map ,
  $strategy: String ,
  $services: [Map],
  $envMapArgs: [String],
  $envArgs: String,)
  {
    deployImage(input: { appId: $appId, image: $image, definition: $definition,strategy: $strategy,services: $services, envMapArgs: $envMapArgs, envArgs: $envArgs }) {
      release {
            id
            version
            reason
            description
            deploymentStrategy
            user {
              id
              email
              name
            }
            createdAt
      }
    }
  }
`
const START_BUILD = gql`
  mutation startBuildApp($appId:String!, $sourceUrl: String!, $sourceType: String!, $buildType: String!, $imageTag: String!, $fileExtension: String!){
  startBuild(input:{
    appId:$appId
    sourceUrl:$sourceUrl
    sourceType:$sourceType
    buildType:$buildType
    imageTag:$imageTag
    fileExtension:$fileExtension
  }){
    build {
      id
      inProgress
      status
      
      user {
        id
        name
        email
      }
      logs
      image
      createdAt
      updatedAt
    }
  }
}
`
const BUILTIN_TYPES = gql`
query{
  GetAvailableBuiltIn(first:10)
}`

const CONFIG_DEFINITION = gql`
  query AppQueryConfigDefinition($name: String!){
  app(name: $name){
    id
    config {
      definition
    }
  }
}
`





const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'grey',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export default function ServiceDialogSlide() {
  const match = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [portno, setportno] = React.useState("");
  const [dockerImage, setDockerImage] = React.useState("");
  const [progress, setProgress] = React.useState(false)
  const [def, setDef] = React.useState({})
  const [portValidation, setPortValidation] = React.useState(false);
  const [exPortValidation, setExPortValidation] = React.useState(false);
  const [isRedirect, setIsRedirect] = React.useState(false)
  const [deployProgress, setDeployProgress] = React.useState(false)
  const [ExternalPortNo, setExternalPortNo] = React.useState("")

  const [uploadFile, setUploadFile] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [dockerImageValidation, setDockerImageValidation] = React.useState(true);
  const [dockerFileValidation, setDockerFileValidation] = React.useState(true);
  const [envMap, setEnvMap] = React.useState([])
  const [isValidated, setIsValidated] = React.useState(false)
  const [isBuiltIn, setIsBuiltIn] = React.useState(false)

  const [envKeyError, setEnvKeyError] = useState("");
  const [envValueError, setEnvValueError] = useState("");

  const [sourceType, setSourceType] = useState("")
  const [builtType, setBuiltType] = useState("")
  const [runtime, setruntime] = useState("")

  const [hideBuiltIn, sethideBuiltIn] = useState(false)

  const history = useHistory();

  var definitionMap = new Map();

  const { data: appdata, error: apperr, loading: appload } = useQuery(APP, {
    variables: {
      name: match.params.id
    }, notifyOnNetworkStatusChange: true,
    onCompleted: () => {

      definitionMap.set('definition', appdata.app.config.definition)

      setportno(definitionMap.get("definition").services[0].internal_port.toString())
      setExternalPortNo(definitionMap.get("definition").services[0].external_port.toString())
      setDef(definitionMap.get("definition"))
    }
  })

  const { loading: loadforbulitintypes, error: errofbulitintypes, data: dataofbulitintypes } = useQuery(BUILTIN_TYPES);

  const [updateApp, { data: updateData, loading: updateLoad, error: updateErr }] = useMutation(UPDATE_APP, {
    refetchQueries: [CONFIG_DEFINITION],
    onCompleted: () => {
      setProgress(false)
      setOpen(false)



    }
  });

  const [deployImage, { data: DPData, loading: DPLoad, error: DPErr }] = useMutation(DEPLOY_IMAGE, {
    refetchQueries: [APP_LOCATION_LIST],
    onCompleted: () => {
      setDeployProgress(false)
      history.push(`/deployments/deploy/${match.params.id}`)


      setDockerImage("")

    }
  });

  const [startBuild, { data: buildData, loading: buildLoad, error: buildErr }] = useMutation(START_BUILD, {
    refetchQueries: [CONFIG_DEFINITION],
    onError: (e) => {

    },
    onCompleted: () => {

      setProgress(false)
      //setOpen(false)

    }
  });


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setDockerImage("")
    setDockerFileValidation(true)
    setDockerImageValidation(true)
    // setSourceType("")
    // setBuiltType("")
    // setruntime("")
    if (buildData) { buildData.startBuild.build.image = null; }
    setUploadFile("")
    setOpen(true);
    setIsValidated(false)
    sethideBuiltIn(false)
  };

  const handleClose = () => {
    setDockerImage("")
    setDockerFileValidation(true)
    setDockerImageValidation(true)
    setOpen(false);
    setUploadFile("")

  };

  const handleReset = () => {
    setDockerImage("")
    setDockerFileValidation(true)
    setDockerImageValidation(true)
    setExternalPortNo(80)
    setportno(3000)
    setIsBuiltIn(false)
    sethideBuiltIn(false)
    // setSourceType("")
    // setBuiltType("")
    setruntime("")

    if (buildData) { buildData.startBuild.build.image = null; }
    setUploadFile("")
    setIsValidated(false)
  };

  const handleSubmit = (e) => {
    setProgress(true)

    updateApp({ variables: { appId: match.params.id, build: "", internalPort: portno.toString(), externalPort: ExternalPortNo.toString() } })

  }

  const handleDeploy = (e) => {
    setDeployProgress(true)
    var temp = []

    for (let [key, value] of Object.entries(inputFields)) {
      if (value["key"] !== "" || value["value"] !== "") {

        temp.push(value["key"] + "=" + value["value"])
      }

    }

    if (buildData) {
      setDockerImage(buildData.startBuild.build.image)


      deployImage({ variables: { appId: match.params.id, image: buildData.startBuild.build.image, definition: def, envMapArgs: temp, envArgs: "" } });
    }
    else {
      deployImage({ variables: { appId: match.params.id, image: dockerImage, definition: def, envMapArgs: temp, envArgs: "" } });
    }

  }

  const uploadFunction = async (e) => {
    if (e[0]) {
      await axios
        .post(`${CONFIG_APP_BASE_API}/api/v1/fileUpload/${match.params.id}?fileExtension=${e[0].name.split('.').pop()}`, e[0], { headers: { "Content-Type": e.type } })
        .then((response) => {
          setDockerFileValidation(false)
          setIsValidated(true)
          startBuild({
            variables: {
              appId: match.params.id,
              sourceUrl: response.data.s3Link,
              sourceType: sourceType,
              buildType: builtType,
              fileExtension: response.data.s3Link.split('.').pop(),
              imageTag: `nife123/${match.params.id}:deployment-${Math.random().toString(20).substr(2, 6)}`
            }
          })
          // successfully uploaded response
        })
        .catch((error) => {
          // error response
        });
    }
  };

  const [inputFields, setInputFields] = useState([
    { key: '', value: '' }
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ key: '', value: '' });
    setInputFields(values);

  };

  const handleRemoveFields = index => {
    if (Object.keys(inputFields).length > 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }

  };

  const handleChangeBuiltInTypes = (e) => {

    //setDockerFileValidation(true)
    setIsBuiltIn(true)
    setSourceType(e.target.value)
    setBuiltType("Builtin")
    setruntime(e.target.value)

  }
  if (loadforbulitintypes) return "loading..";

  var temp = ""
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    const envValue = [...envMap];

    if (event.target.name === "key") {
      temp = temp + event.target.value
      const newValue = event.target.value;
      if (!newValue.match(/[a-z]{1,15}/)) {
        setEnvKeyError("");
      } else {
        setEnvKeyError("Unforbidden character: %<>$'\"");
      }
      values[index].key = event.target.value;

    } else {
      const newValue = event.target.value;
      if (!newValue.match(/[a-z]{1,15}/)) {
        setEnvKeyError("");
      } else {
        setEnvKeyError("Unforbidden character: %<>$'\"");
      }
      values[index].value = event.target.value;
    }

    envValue[index] = temp + "=" + event.target.value
    setEnvMap(envValue)
    setInputFields(values);
  };

  const valuevalidatePort = (e) => {
    var regexp = /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
    const checkvalue = regexp.test(e.target.value.toLowerCase())
    if (checkvalue.length > 0) {
      if (e.target.name = "externalPort") {
        setExPortValidation(false)
      }
      else {
        setPortValidation(false)
      }

    }


    if (e.target.name == "externalPort") {
      setExPortValidation((checkvalue) ? false : true)
    }
    else {
      setPortValidation((checkvalue) ? false : true)
    }
  }

  const Divider = ({ children }) => {
    const classes = useStyles();

    // if (isRedirect){
    //   return <Redirect to={`/deployments/deploy/${match.params.id}`}/>
    // }
    if (buildData) {

      setDockerImage(buildData.startBuild.build.image)
    }
    if (dockerImage === "") {
      setIsValidated(false)
    }
    return (
      <div className={classes.hrcontainer} >
        <div className={classes.hrborder} />
        <span className={classes.hrcontent}>
          {children}
        </span>
        <div className={classes.hrborder} />
      </div>
    );
  };



  return (
    <div>
      <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Config
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Services and Add-ons"}</DialogTitle>
        <DialogContent>

          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Configure" {...a11yProps(0)} />
            {/* <Tab label="Create a Service" {...a11yProps(1)} /> */}
          </Tabs>

          <TabPanel value={value} index={0}>

            <Grid container direction="column" style={{ padding: "10px 0px 10px" }}>
              {!hideBuiltIn &&
                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                  <InputLabel id="demo-simple-select-filled-label">Choose Runtime (Optional)</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    label="Choose Runtime (Optional)"
                    onChange={handleChangeBuiltInTypes}
                    value={runtime}
                  >
                    <MenuItem> None </MenuItem>
                    {dataofbulitintypes.GetAvailableBuiltIn.map((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}

                  </Select>
                </FormControl>}





              <Grid item xs>
            
                {dockerImageValidation && <div style={{ textAlign: "center", margin: "10px", color: "gray" }}>

                  <Grid container direction="row" alignItems="center">
                    <CheckCircleIcon style={{ color: "green" }} /> File uploaded successfully
                  </Grid>



                  {buildData && <Grid container direction="row" alignItems="center">
                    <CheckCircleIcon style={{ color: "green" }} /> Ready to deploy!
                  </Grid>}


                  {buildErr && <Grid container direction="row" alignItems="center">
                    <ErrorOutlineIcon style={{ color: "red" }} /> {buildErr.message} (please RESET and try again)
                  </Grid>}
                </div>
            }

                {!isBuiltIn && (dockerFileValidation ? (dockerImageValidation ? <Divider>Or</Divider> : null) : null)}

                {!isBuiltIn && (dockerFileValidation && <TextField variant="outlined" style={{ margin: "0px 0px 20px 0px" }} required fullWidth label="Docker Image Name" value={dockerImage} onChange={(e) => {
                  setDockerImage(e.target.value);
                  setDockerImageValidation(false);

                  sethideBuiltIn(true)
                  setIsValidated(true)
                }} />
                )}


                <hr />



              </Grid>

              <Grid container>
                <Grid item style={{ width: "50%" }}>
                  <TextField
                    required
                    style={{ width: "95%" }}
                    variant="filled"
                    fullWidth
                    name="internalPort"
                    error={portValidation && true}
                    helperText={portValidation && "Invalid Port Number, Range is (1-65535)"}
                    label="Internal port number"
                    value={portno}
                    onChange={(e) => { setportno(e.target.value); valuevalidatePort(e) }} />

                </Grid>

                <Grid item style={{ width: "50%" }}>

                  <TextField
                    required
                    variant="filled"
                    fullWidth
                    name="externalPort"
                    error={exPortValidation && true}
                    helperText={exPortValidation && "Invalid Port Number, Range is (1-65535)"}
                    label="External port number"
                    value={ExternalPortNo}
                    onChange={(e) => { setExternalPortNo(e.target.value); valuevalidatePort(e) }} />

                </Grid>
                <h3> Environment Args </h3>
                <TableContainer component={Paper}>

                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="left">Value</TableCell>
                        <TableCell align="right"><Button onClick={() => handleAddFields()} style={{ color: "#4A047A" }} startIcon={<AddIcon />}> Add</Button></TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inputFields.map((inputField, index) => (
                        <Fragment key={`${inputField}~${index}`}>
                          <TableRow key={1}>
                            <TableCell component="th" scope="row">
                              <TextField value={inputField.key} inputProps={{ className: classes.input, pattern: "[a-z][A-Z]{1,15}" }} name="key" onChange={event => handleInputChange(index, event)} placeholder="PORT" />
                            </TableCell>
                            <TableCell align="right">
                              <TextField value={inputField.value} name="value" onChange={event => handleInputChange(index, event)} placeholder="8080" />
                            </TableCell>
                            <TableCell align="right"><Button onClick={() => handleRemoveFields(index)}><DeleteIcon /></Button></TableCell>

                          </TableRow>
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </Grid>


            </Grid>

          </TabPanel>


          <TabPanel value={value} index={1}>
            {/* <Grid container>
            <Grid item fullWidth> */}

            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-filled-label">Choose Runtime</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Choose Runtime"
              /*onChange={handleChange}*/
              >

                {dataofbulitintypes.GetAvailableBuiltIn.map((value) => (
                  <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                ))}

              </Select>
            </FormControl>
            {/* </Grid> */}

            {/* <Grid item style={{width:"50%"}}>
                <FormControl variant="filled" style={{width:"100%"}}className={classes.formControl}>
                 
                  <TextField variant="outlined" fullWidth  label="Source"/>
                </FormControl>
            </Grid> */}

            {/* </Grid> */}

            <Grid container>

              <Grid item style={{ width: "100%" }}>
                <FormControl style={{ width: "100%" }} variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label1"> Machine Learning Tools. </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label1"
                    id="demo-simple-select-filled"
                    label="Machine Learning Tools."

                  /*onChange={handleChange}*/
                  >

                    <MenuItem value={10}>Tensorflow</MenuItem>
                    <MenuItem value={20}>Scikit lear</MenuItem>
                    <MenuItem value={30}>Pytorch</MenuItem>
                    <MenuItem value={40}>
                      Google Cloud AutoML
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            <Grid container>
              <Grid item style={{ width: "100%" }}>
                <FormControl style={{ width: "100%" }} variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label2"> Acceleration </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label2"
                    id="demo-simple-select-filled"
                    label="Acceleration"

                  /*onChange={handleChange}*/
                  >

                    <MenuItem value={10}>Intel Open VINO</MenuItem>
                    <MenuItem value={20}>NVIDIA CUDA-X AI</MenuItem>

                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            {/* <Divider style={{margin:"0px"}}/> */}
            {/* <DropzoneArea
              clearOnUnmount={false}
              Icon={SiDocker}
              filesLimit={1}
              //getFileAddedMessage={nu}
              showFileNamesInPreview={false}
              acceptedFiles={['application/*']}
              useChipsForPreview={false}
              showPreviewsInDropzone={false}
              dropzoneText={"Drag 'n' drop Docker Archive File here, or click to select"}
              onChange={(e) => {uploadFunction(e)}}
              style={{marginBottom:"10px"}}
              />  */}
            <p style={{ textAlign: "center", fontWeight: "600" }}>Args</p>

            {/*<Grid container>

              <Grid>
              Name
              </Grid>
   
              <Grid>
              Vlaue
              </Grid>
   
              <Grid>
              </Grid>

            </Grid>
 
            <Grid container>

              <Grid>
                <FormControl variant="filled" className={classes.formControl}>
                 
                  <TextField variant="outlined" fullWidth  label="Source"/>
                </FormControl>
              </Grid>
   
              <Grid>
                <FormControl variant="filled" className={classes.formControl}>
                 
                  <TextField variant="outlined" fullWidth  label="Source"/>
                </FormControl>
              </Grid>
   
              <Grid>
              <Button> D</Button>
              </Grid>

            </Grid>
            <Grid container style={{flexWrap:"nowarp"}}>

              <Grid item>
                <FormControl variant="filled" className={classes.formControl}>
                 
                  <TextField variant="outlined" fullWidth  label="Source"/>
                </FormControl>
              </Grid>
   
              <Grid item>
                <FormControl variant="filled" className={classes.formControl}>
                 
                  <TextField variant="outlined" fullWidth  label="Source"/>
                </FormControl>
              </Grid>
   
              <Grid>
              <Button> D</Button>
              </Grid>

            </Grid>
 
            <Grid container>
              <Button> Add agrs</Button>
            </Grid>
 
            <Grid container>
              <Button> Add service</Button>
            </Grid>*/}

            {/* <TableContainer component={Paper} style={{margin:"10px"}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="right"><Button style={{ color:"#4A047A"}} startIcon={<AddIcon/>}> agrs</Button></TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow key={1}>
              <TableCell component="th" scope="row">
                <TextField label="--port"/>
              </TableCell>
              <TableCell align="right"><TextField label="8080"/></TableCell>
              <TableCell align="right"><Button><DeleteIcon/></Button></TableCell>
              
            </TableRow>

            <TableRow key={2}>
              <TableCell component="th" scope="row">
                <TextField label="--bind"/>
              </TableCell>
              <TableCell align="right"><TextField label="0.0.0.0"/></TableCell>
              <TableCell align="right"><Button><DeleteIcon/></Button></TableCell>
              
            </TableRow>
          
        </TableBody>
      </Table>
      
    </TableContainer> */}
            <TableContainer component={Paper}>

              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Value</TableCell>
                    <TableCell align="right"><Button onClick={() => handleAddFields()} style={{ color: "#4A047A" }} startIcon={<AddIcon />}> Add</Button></TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputFields.map((inputField, index) => (
                    <Fragment key={`${inputField}~${index}`}>
                      <TableRow key={1}>
                        <TableCell component="th" scope="row">
                          <TextField value={inputField.key} inputProps={{ className: classes.input, pattern: "[a-z]{1,15}" }} name="key" onChange={event => handleInputChange(index, event)} placeholder="PORT" />
                        </TableCell>
                        <TableCell align="right">
                          <TextField value={inputField.value} name="value" onChange={event => handleInputChange(index, event)} placeholder="8080" />
                        </TableCell>
                        <TableCell align="right"><Button onClick={() => handleRemoveFields(index)}><DeleteIcon /></Button></TableCell>

                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>

            </TableContainer>
            <Grid container>

            </Grid>

            <Grid container>
              <Button startIcon={<CheckIcon />} style={{ textAlign: "center", margin: "10px", fontWeight: 700, backgroundColor: "#6089FE", color: "#fff" }}> Add service</Button>
            </Grid>
          </TabPanel>

        </DialogContent>
        <DialogActions>

          <Button onClick={handleReset} variant="outlined">
            Reset
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>

          {isValidated && <Button disableElevation variant="contained" color="primary" onClick={handleSubmit} disabled={portValidation || exPortValidation ? true : false}>
            {progress && <CircularProgress style={{ color: "white" }} size={20} />}Submit
          </Button>}
        </DialogActions>
      </Dialog>
      <br />
      <div className="some-container">
        {
          (() => {
            if (buildErr)
              return <Button disabled={!dockerImage && true} startIcon={deployProgress ? <CircularProgress style={{ color: "red" }} size="20px" /> : <ErrorOutlineIcon style={{ color: "red" }} />} variant="outlined" style={{ backgroundColor: "#fff", color: "#111", margin: "20px 0px", border: "red 1px solid" }} onClick={handleDeploy}>
                {buildErr.message}, try again
              </Button>

            if (buildData)
              return <Button disabled={!buildData} startIcon={deployProgress ? <CircularProgress style={{ color: "white" }} size="20px" /> : <CheckIcon />} variant="outlined" style={{ backgroundColor: "#6089FE", color: "#fff", margin: "20px 0px" }} onClick={handleDeploy}>
                {!buildData ? "..." : "Create Deployment"}
              </Button>
            if (buildLoad)
              return <Button disabled={!buildData} startIcon={<CircularProgress size="20px" />} variant="outlined" style={{ backgroundColor: "#fff", color: "#111", margin: "20px 0px" }} onClick={handleDeploy}>
                {!buildData ? "Please wait..." : "Create Deployment"}
              </Button>
            if (true)
              return <Button disabled={!dockerImage && true} startIcon={deployProgress ? <CircularProgress style={{ color: "white" }} size="20px" /> : null} variant="outlined" style={{ backgroundColor: "#6089FE", color: "#fff", margin: "20px 0px" }} onClick={handleDeploy}>
                Create Deployment!
              </Button>

          })()
        }
      </div>
      {/* { buildData ? ({buildLoad ? ("Please wait") : "NOO"}) :
    <Button disabled={!buildData} startIcon={deployProgress ? <CircularProgress style={{color:"white"}} size="20px"/>: <CheckIcon/>} variant="outlined" style={{backgroundColor:"#6089FE", color:"#fff",margin:"20px 0px"}} onClick={handleDeploy}>
    {!buildData ? "Please wait..." : "Create Deployment"}
     </Button>} */}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
