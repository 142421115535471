import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, Grid, Skeleton, Chip, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMetrics from './ExpandMetrics';
import { FaExpand } from 'react-icons/fa';
import { CONFIG_APP_BASE_COMPANY, CONFIG_APP_METRICS_BASE_URL } from '../../../configApp';

// Define GraphQL query
export const ORG_SLUG_FETCH = gql`
  query OrgSlugQuery($name: String!) {
    app(name: $name) {
      id
      organization {
        id
        name
        slug
      }
    }
  }
`;

const list = [
  {
    "type": "cpu",
    "title": "total CPU Usage ",
    "desc": "Total CPU usage percentage across all processes and containers.",
    "panelId": 1
  },
  {
    "type": "cpu",
    "title": "CPU Usage per pod",
    "desc": "CPU usage per pod shows the percentage of CPU resources used by each pod.",
    "panelId": 13
  },
  {
    "type": "cpu",
    "title": "Container CPU Usage",
    "desc": "Average CPU usage percentage for the container across all available processors.",
    "panelId": 33
  },
  {
    "type": "cpu",
    "title": "CPU Usage avg per pod",
    "desc": "Average CPU usage percentage per pod over a specified period.",
    "panelId": 8
  },

  {
    "type": "memory",
    "title": "RSS Memory",
    "desc": "Resident Set Size represents the portion of memory occupied by a process.",
    "panelId": 25
  },
  {
    "type": "memory",
    "title": "Container Memory Usage",
    "desc": "Total amount of memory currently used by the container.",
    "panelId": 34
  },
  {
    "type": "memory",
    "title": "Memory Usage per pod",
    "desc": "Memory usage by each individual pod, measured in bytes.",
    "panelId": 14
  },
  {
    "type": "memory",
    "title": "Memory Usage avg per pod",
    "desc": "Average memory usage per pod over a specified period, measured in bytes.",
    "panelId": 9
  },
  {
    "type": "io",
    "title": "IO Read",
    "desc": "Number of read operations performed by the container, including file, network, and device I/Os.",
    "panelId": 28
  },
  {
    "type": "io",
    "title": "IO Write",
    "desc": "Number of write operations performed by the container, including file, network, and device I/Os.",
    "panelId": 27
  },
  {
    "type": "net",
    "title": "Net Received",
    "desc": "Amount of network traffic received by the container.",
    "panelId": 32
  },
  {
    "type": "net",
    "title": "Net Sent",
    "desc": "Amount of network traffic sent by the container.",
    "panelId": 31
  },
  {
    "type": "pid",
    "title": "Container Process",
    "desc": "Process ID assigned to each process running within the container.",
    "panelId": 26
  },
  {
    "type": "pid",
    "title": "PID Thread Count",
    "desc": "Number of active threads within a specific process.",
    "panelId": 35
  },
  {
    "type": "others",
    "title": "Restarts",
    "desc": "Number of times the application or container has been restarted.",
    "panelId": 30
  },
  {
    "type": "others",
    "title": "Running",
    "desc": "Current status indicating that the application or container is actively running.",
    "panelId": 29
  },
  {
    "type": "others",
    "title": "Uptime",
    "desc": "Duration of time that the application or system has been running without interruption.",
    "panelId": 36
  }
]


// Define Iframe component
export const IframeComponent = ({ appName, orgId, panelId, timeRange, title, desc }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { from, to } = timeRange;
  const iframeSrc = `${CONFIG_APP_METRICS_BASE_URL}/d-solo/nife-test/${CONFIG_APP_BASE_COMPANY === "zeke" ? "kubernetes-app-metrics-pod" : "kubernetes-app-metrics-nife"}?orgId=1&var-namespace=${CONFIG_APP_BASE_COMPANY === "zeke" ? "" : orgId.toString().toLowerCase()}&var-container=${CONFIG_APP_BASE_COMPANY === "zeke" ? "" : appName}&from=${from}&to=${to}&theme=${theme ? theme.palette.mode : "light"}&panelId=${panelId}`;

  return (
    <>
      {open ?
        <>
          <ExpandMetrics
            title={title}
            desc={desc}
            open={open}
            onClose={handleClose}
          >
            <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="80%" title={`panel-${panelId}`}></iframe>
          </ExpandMetrics>

        </> :
        <>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant='h6' sx={{ textTransform: "capitalize", mt: 1 }}>{title}</Typography>

            <IconButton variant="outlined" onClick={handleClickOpen}>
              <FaExpand />
            </IconButton>
          </Grid>


          <Typography style={{ color: "grey", marginBottom: "10px" }} variant='body2'>{desc}</Typography>

          <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="200" title={`panel-${panelId}`}></iframe>


        </>
      }

    </>

  );
};

// Time range options
const timeOptions = [
  { label: 'Last 30 minutes', value: 30 * 60 * 1000 },
  { label: 'Last 1 hour', value: 60 * 60 * 1000 },
  { label: 'Last 6 hours', value: 6 * 60 * 60 * 1000 },
  { label: 'Last 12 hours', value: 12 * 60 * 60 * 1000 },
  { label: 'Last 24 hours', value: 24 * 60 * 60 * 1000 },
  { label: 'Last 7 days', value: 7 * 24 * 60 * 60 * 1000 },
  { label: 'Last 30 days', value: 30 * 24 * 60 * 60 * 1000 },
];

// Define GraphsDisplay component
export default function GraphsDisplay() {

  const [orgId, setOrgId] = useState(null);
  const [filterValue, setfilterValue] = useState("cpu");
  const [timeRange, setTimeRange] = useState({ from: Date.now() - (30 * 60 * 1000), to: Date.now() });
  const match = useRouteMatch();
  const [fetchOrgSlug] = useLazyQuery(ORG_SLUG_FETCH, {
    variables: { name: match.params.id },
    onCompleted: (data) => {
      if (data && data.app.organization) {
        setOrgId(data.app.organization.slug);
      }
    },
  });

  useEffect(() => {
    fetchOrgSlug();
  }, [fetchOrgSlug]);


  // Handle time range change
  const handleTimeRangeChange = (event) => {
    const selectedValue = event.target.value;
    setTimeRange({
      from: Date.now() - selectedValue,
      to: Date.now(),
    });
  };

  if (orgId === null) {
    return 'Fetching...';
  }

  return (
    <div>

      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h4" sx={{ p: 4, pl: 0 }} component="h1" >System Performance</Typography>
        </Grid>
        <Grid item>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Time Range</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              label="Time Range"
              value={timeRange.to - timeRange.from}
              onChange={handleTimeRangeChange}
            >
              {timeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ mb: 2 }}
      >
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "" ? "filled" : "outlined"} label="Show All" color="primary" onClick={() => setfilterValue("")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "cpu" ? "filled" : "outlined"} label="CPU (4 Charts) " color="primary" onClick={() => setfilterValue("cpu")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "memory" ? "filled" : "outlined"} label="Memory (4 Charts) " color="primary" onClick={() => setfilterValue("memory")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "io" ? "filled" : "outlined"} label="IO (2 Charts) " color="primary" onClick={() => setfilterValue("io")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "net" ? "filled" : "outlined"} label="Network (2 Charts) " color="primary" onClick={() => setfilterValue("net")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "pid" ? "filled" : "outlined"} label="Process Id (2 Charts) " color="primary" onClick={() => setfilterValue("pid")} />}
        </Grid>
        <Grid item>
          {orgId === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "others" ? "filled" : "outlined"} label="Others (3 Charts) " color="primary" onClick={() => setfilterValue("others")} />}
        </Grid>

      </Grid>

      <Grid container spacing={2}>
        {orgId && list.map((e) => {
          if (e.type === filterValue || filterValue === "") {
            return (
              <Grid item xs={12} sm={12} md={6}>


                <IframeComponent
                  key={e.panelId}
                  appName={match.params.id}
                  orgId={orgId}
                  title={e.title}
                  desc={e.desc}
                  panelId={e.panelId}
                  timeRange={timeRange}
                />
              </Grid>
            )
          }
        })}

      </Grid>
    </div>
  );
}
