import React, { useState } from 'react';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { BiWorld } from 'react-icons/bi';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CONFIG_APP_UPTIME_METRICS_BACKEND_URL, CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../../../configApp';

export default function CustomDomain({ data }) {
    const [showDetails, setShowDetails] = React.useState(false);
    const [customDomainName, setcustomDomainName] = useState("");
    const handleDialogClose = () => {
        setShowDetails(false);
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleCustomDomain = () => {
        try {
            const res = axios.post(
                `${CONFIG_APP_UPTIME_METRICS_BASE_URL}/sync-dns-record-site/`, // Replace with your API endpoint
                {
                    domain_name: customDomainName,        // Send the data as JSON
                    bucket_name: data ? data : "", // Another field example
                },
                {
                    headers: {
                        'Content-Type': 'application/json', // Ensuring the data is sent as JSON
                    },
                }
            );
            if (res) {
                enqueueSnackbar("Successfully synced!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }


        } catch (err) {
            // Handle error
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <div>
            <h3>Custom Domain</h3>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>

                    Please click on the button to add your custom domain.

                </Typography>
                <Button
                    variant='contained'
                    startIcon={<BiWorld />}
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {"Add Custom Domain for Site"}
                </Button>
            </Grid>

            <Dialog maxWidth={"md"} open={showDetails} onClose={handleDialogClose}>
                <DialogTitle id="alert-dialog-title">
                    Add Custom Domain for Site
                </DialogTitle>
                <DialogContent sx={{ padding: 3 }}>
                 


                    <TableContainer sx={{
                        background: "rgb(237, 237, 237)",
                        padding: "20px",
                        borderRadius: "12px",
                        boxShadow: 0,
                        mb: 2,
                    }} component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="domain DNS records">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: '#444' }}>Record Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: '#444' }}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>CNAME</TableCell>
                                    <TableCell sx={{ color: '#1976d2', fontWeight: 'bold', fontFamily: 'monospace' }}>
                                        <pre style={{ margin: 0 }}>https://{data}</pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>Name</TableCell>
                                    <TableCell sx={{ color: '#000', fontWeight: 'bold' }}>
                                        <TextField
                                            size="small"
                                            autoFocus={true}
                                            value={customDomainName}
                                            onChange={(e) => setcustomDomainName(e.target.value)}
                                            label="Enter Your Domain"
                                            placeholder='google.com'
                                            variant="outlined"
                                            required
                                            sx={{
                                                width: '250px',
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                borderRadius: '8px',
                                            }}
                                        />
                                        <span style={{ color: "grey" }}>ex. google.com, facebook.com</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ color: '#2c3e50', fontFamily: 'monospace' }}>TTL (optional)</TableCell>
                                    <TableCell sx={{ color: '#777', fontWeight: 'bold' }}>doesn't matter</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography sx={{ mb: 2, fontSize: '1rem', fontWeight: 'bold' }} variant="subtitle1">
                        <span style={{ color: '#1976d2' }}>Please Note:</span> You need to make sure that the following records are added to your domain's DNS records, Once added click on below button to sync.
                    </Typography>
                    <Button
                        disabled={!customDomainName}
                        onClick={handleCustomDomain}
                        variant="contained"
                        color="primary"
                        sx={{
                            padding: '10px 20px',
                            fontWeight: 'bold',
                        }}
                    >
                        I Configured my dns record, Let's Sync
                    </Button>

                    <Typography variant="body2" sx={{ marginTop: 2, fontSize: '0.9rem', color: '#777' }}>
                        It may take up to 24 hours for domain propagation
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
