import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';
import axios from 'axios';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../../configApp';

const LogsDialog = ({ open, onClose, serviceName, ip }) => {
  const [logs, setLogs] = useState([]); // Store logs as an array
  const [loading, setLoading] = useState(true); // Loading state for logs

  useEffect(() => {
    if (open) {
      fetchLogsData();
    }
  }, [open]);

  // Function to fetch logs data from the backend
  const fetchLogsData = async () => {
    try {
      setLoading(true);
    
      const response = await axios.post(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/get-monolith-logs`, {
        ipAddress: ip,
        name: `customer-stack3_${serviceName}`,
      });

      // Assuming the response has a logs array in `response.data.data.logs`
      setLogs(response.data.data?.logs || []); // Set logs data, default to empty array
    } catch (error) {
      setLogs([]); // Set empty logs on error
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Logs</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading logs...</p>
        ) : logs.length === 0 ? (
          <p>No logs available.</p>
        ) : (
          <Box
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
              fontFamily: "'Courier New', monospace",
              background: "black",
              color:"white",
            }}
          >
            {logs.map((log, index) => (
              <Box
                key={index}
                sx={{
                  padding: '8px',
                  borderBottom: '1px solid #ddd',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                <pre>{log}</pre>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogsDialog;
